import { IChartJSData } from './IChartJSData'

interface csvRowData {
  [key: string]: number
}

interface csvTimestampLookup {
  [key: number]: csvRowData
}


export function ChartDataToCSV(chartData: IChartJSData): string {

  if (!chartData.datasets || chartData.datasets.length === 0) {
    return ''
  }

  const cols = [] as string[]
  const times = [] as number[]

  const out = {} as csvTimestampLookup
  let i = 0
  for (const data of chartData.datasets) {
    const label = data.label || 'column ' + i
    cols.push(label)
    for (const sample of data.data) {
      let row = {} as csvRowData
      if (!(sample.x in out)) {
        out[sample.x] = row
        times.push(sample.x)
      } else {
        row = out[sample.x]
      }
      row[label] = sample.y
    }
  }

  times.sort(function(a, b) {
    return a - b
  })

  const rows = ['time,' + cols.join(',')] as string[]
  for (const t of times) {
    const row = [t.toString()] as string[]
    if (!(t in out)) {
      continue
    }
    for (const col of cols) {
      if (col in out[t]) {
        row.push(out[t][col].toString())
      } else {
        row.push('')
      }
    }
    rows.push(row.join(','))
  }
  return rows.join('\n')
}