import { IChartJSDataset } from "./IChartJSData"

export interface ChartLineColor {
  value: string
  name: string
}

export function DefaultChartLineColorList() {
  return [
    {
      value: "#3399ffcc",
      name: "Blue"
    },
    {
      value: "#ff9933cc",
      name: "Orange"
    },
    {
      value: "#22aa22cc",
      name: "Green"
    },
    {
      value: "#cc1111cc",
      name: "Red"
    },
    {
      value: "#9922cccc",
      name: "Purple"
    },
    {
      value: "#00a090cc",
      name: "Teal"
    },
    {
      value: "#ffa0a0cc",
      name: "Pink"
    },
    {
      value: "#e8d066cc",
      name: "Yellow"
    },
    {
      value: "#4455eecc",
      name: "Indigo"
    },
    {
      value: "#ee2299cc",
      name: "Fuschia"
    },
    {
      value: "#995f2299",
      name: "Brown"
    },
    {
      value: "#99999999",
      name: "Gray"
    },
    {
      value: "#006633ee",
      name: "Hunter Green"
    },
    {
      value: "#0033aacc",
      name: "Dark Blue"
    },
    {
      value: "#661166cc",
      name: "Plum"
    },
    {
      value: "#770000dd",
      name: "Maroon"
    },
    {
      value: "#f6602dcc",
      name: "Blood Orange"
    },
    {
      value: "#55555599",
      name: "Dark Gray"
    },
  ]
}

interface SHIColorLookup {
  [key: number]: string
}

const SHIColors = {
  1: '#3A73BACC',
  2: '#5581A6CC',
  3: '#78957ECC',
  4: '#A8AD45CC',
  5: '#C6C13FCC',
  6: '#D7C73CCC',

} as SHIColorLookup

export function GetSHIColor(value: number): string {
  if (value in SHIColors) {
    return SHIColors[value]
  }
  return '#00000000'
}

type NextColorFunc = (label: string, value: number,
  colors: ChartColorManager) => string

type SetLineColorsFunc = (dslist: IChartJSDataset[],
  colors: ChartColorManager) => void

export interface LineColorCache {
  [key: string]: string
}

interface ChartColorManager {
  Laps: number
  Colors: ChartLineColor[]
  Cache: LineColorCache
  Next: NextColorFunc
  SetLineColors: SetLineColorsFunc
  Used: string[]
}

export function DefaultNextColor(label: string, value: number,
  colors: ChartColorManager): string {

  if (label in colors.Cache) {
    return colors.Cache[label]
  }

  if (colors.Used.length === colors.Colors.length) {
    colors.Used = [] as string[]
    console.log('lapped colors temporary notification', colors.Laps)
  }

  for (const color of colors.Colors) {
    if (colors.Used.includes(color.value)) {
      continue
    }
    colors.Cache[label] = color.value
    colors.Used.push(color.value)
    StoreColors(colors.Cache)
    return color.value
  }
  return "#999999CC"
}

export function DefaultSetLineColors(dslist: IChartJSDataset[],
  colors: ChartColorManager) {
  for (const ds of dslist) {
    if (ds.label && ds.label in colors.Cache) {
      ds.borderColor = colors.Cache[ds.label]
      ds.backgroundColor = colors.Cache[ds.label]
      colors.Used.push(colors.Cache[ds.label])
    }
  }
  for (const ds of dslist) {
    if (ds.borderColor || !ds.label) {
      continue
    }
    const color = colors.Next(ds.label, 0, colors)
    ds.backgroundColor = color
    ds.borderColor = color
  }
}


export function DefaultColorManager(): ChartColorManager {
  return {
    Laps: 0,
    Colors: DefaultChartLineColorList(),
    Next: DefaultNextColor,
    Cache: LoadStoredColors(),
    Used: [] as string[],
    SetLineColors: DefaultSetLineColors
  } as ChartColorManager
}

const defaultColorCacheKey = 'default.lineColors.cache'

export function LoadStoredColors(): LineColorCache {
  const vals = localStorage.getItem(defaultColorCacheKey)
  if (!vals) {
    return {} as LineColorCache
  }
  try {
    return JSON.parse(vals) as LineColorCache
  }
  catch {
    return {} as LineColorCache
  }
}

export function StoreColors(cache: LineColorCache)  {
  localStorage.setItem(defaultColorCacheKey, JSON.stringify(cache))
}
