import { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEdit } from '@fortawesome/free-regular-svg-icons'
import { ModalTimePicker } from './ModalTimePicker'
import { parseTimeRange, TimeRanges } from '../../../time/timeRanges'

import BasicPanel from '../Panels/basic/BasicPanel'

import './TimePicker.css'

export type timeChangedFunc = (tr: TimeRanges) => void

interface TimePickerProps {
  defaultValue?: string
  timeChangeFunc: timeChangedFunc
  label?: string
  labelClass?: string
  wrapperClass?: string
}

const defaultTimeRange = '24h-'

export default function TimePicker(props: TimePickerProps) {

  const { defaultValue, labelClass, wrapperClass, timeChangeFunc } = props

  const [isPickerVisible, setIsPickerVisible] = useState(false)
  const [tr, setTR] = useState<TimeRanges>(parseTimeRange(defaultValue || defaultTimeRange))

  const timeChanged = (newTimeRange: TimeRanges) => {
    setTR(newTimeRange)
  }

  useEffect(() => {
    timeChangeFunc(tr)
  }, [tr, timeChangeFunc])

  const className = (input: string) => {
    if (input.length > 14) {
      return "flex-grow-1 hoverable-text font-reduce-2"
    }
    return "flex-grow-1 hoverable-text"
  }

  const labelClasses = useMemo((): string => {
    const base = 'timepicker-control-label'
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [labelClass])

  const wrapperClasses = useMemo((): string => {
    const base = 'timepicker-control'
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  return (
    <div className={wrapperClasses}>
      {props.label && <div className={labelClasses}>{props.label}:</div>}
      <BasicPanel className="control wide">
        <div className="timepicker-box expand-box expand-box-hoverable">
          <div
            className="timepicker-box-label"
            onClick={() => { setIsPickerVisible(true)}}>
            <FontAwesomeIcon icon={faClock} className="hoverable-icon me-2" />
            <div className={className(tr.formatted)}>{tr.formatted}</div>
            <FontAwesomeIcon icon={faEdit} className="hoverable-icon" />
          </div>
        </div>
      </BasicPanel>
      {isPickerVisible &&
        <ModalTimePicker
          closer={() => setIsPickerVisible(false)}
          timeRange={tr.combined}
          timeChangeFunc={timeChanged}
        />
      }
    </div>
  )

}