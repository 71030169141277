import { useMemo } from "react"

interface LabelProps {
  text?: string
  className?: string
}

export function Label(props: LabelProps) {
  const {text, className} = props
  const classes = useMemo(() => {
    const base = 'general-form-label'
    if (className) {
      return `${base} ${className}`
    }
  }, [className])
  return  <div className={classes}>{text}</div>
}
