import { useCallback } from "react"
import { TimeRanges } from "../../../time/timeRanges"
import { RelativeTimePicker } from "./RelativeTimePicker"

interface RelativeTimeSubPaneProps {
  relTimeSelected: any
  timeRangeUpdated: any
  timeRanges: TimeRanges
}

export function RelativeTimeSubPane(props: RelativeTimeSubPaneProps) {

  const { timeRanges, timeRangeUpdated } = props

  const updateStartMode = useCallback((input: string) => {
    const tr = { ...timeRanges }
    tr.startSign = input === 'future' ? '+' : ''
    tr.startMode = input
    timeRangeUpdated(tr)
  }, [timeRanges, timeRangeUpdated])

  const updateStartTime = useCallback((input: string) => {
    if (input === '+now') {
      input = 'now'
    }
    const tr = { ...timeRanges }
    tr.startVal = input
    tr.startSign = tr.startMode === 'future' || input.startsWith('+') ? '+' : ''
    timeRangeUpdated(tr)
  }, [timeRanges, timeRangeUpdated])

  const updateEndMode = useCallback((input: string) => {
    const tr = { ...timeRanges }
    tr.endSign = input === 'future' ? '+' : ''
    tr.endMode = input
    timeRangeUpdated(tr)
  }, [timeRanges, timeRangeUpdated])

  const updateEndTime = useCallback((input: string) => {
    if (input === '+now') {
      input = 'now'
    }
    const tr = { ...timeRanges }
    tr.endVal = input
    tr.endSign = tr.endMode === 'future' || input.startsWith('+') ? '+' : ''
    timeRangeUpdated(tr)
  }, [timeRanges, timeRangeUpdated])

  return (
    <div className="mb-3">
      <hr />
      <RelativeTimePicker
        title="Starting"
        groupName="startMode"
        mode={props.timeRanges.startMode}
        defaultValue={props.timeRanges.startVal}
        updateModeFunc={updateStartMode}
        updateTimeFunc={updateStartTime}
      />
      <hr />
      <RelativeTimePicker
        title="Ending"
        groupName="endMode"
        mode={props.timeRanges.endMode}
        defaultValue={props.timeRanges.endVal}
        updateModeFunc={updateEndMode}
        updateTimeFunc={updateEndTime}
      />
    </div>
  )

}