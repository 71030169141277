import { useMemo } from "react"
import './ToggleSwitch.css'

interface ToggleSwitchProps {
  label?: string
  labelClass?: string
  onClick?: any
  value?: any
  defaultChecked?: boolean
  disabled?: boolean
  className?: string
  wrapperClass?: string
  id?: string
  sz?: string
}

export default function ToggleSwitch(props: ToggleSwitchProps) {
  const { className, id, disabled, wrapperClass, labelClass, sz } = props

  const checkboxId = useMemo(() => {
    if (id) {
      return id
    }
    return Math.random().toString()
  }, [id])

  const classes = useMemo(() => {
    const base = 'input-control input-control-toggleswitch'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const labelClasses = useMemo(() => {
    let base = 'input-control-label input-control-toggleswitch-label'
    if (disabled) {
      base = `${base} input-control-toggleswitch-label-disabled`
    }
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [disabled, labelClass])

  const wrapperClasses = useMemo(() => {
    let base = 'input-control-toggleswitch-container'
    if (sz) {
      base = `${base} ${base}-sm`
    }
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass, sz])

  return (
    <div className={wrapperClasses}>
      <input
        type="checkbox"
        className={classes}
        disabled={props.disabled}
        id={checkboxId}
        value={props.value}
        defaultChecked={props.defaultChecked}
        onClick={props.onClick}
      />
      <label htmlFor={checkboxId} className={labelClasses} />
    </div>
  )
}
