import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { PrefsContext } from '../../prefs/PrefsContext'
import { createLibraryChart, getLibraryChartsByCustomer, updateLibraryChart } from '../../api/endpoints'
import { ApiStep, LibraryChart } from '../../api/types'
import { GeneralStatus } from '../../types/types'

import { faChartArea } from '@fortawesome/free-solid-svg-icons'

import FolderSelector from './FolderSelector'
import TextInputRow from '../controls/forms/TextInputRow'
import ModalBasic, { ButtonConfig, StatusBanner } from '../controls/modals/ModalBasic'

import './ModalSaveChart.css'

interface ModalSaveChartProps {
  closer?: any
  customerDN: string | undefined
  customerID: string | undefined
  title?: string
  chartDefinition: string
  chartSettings: string
}

export function ModalSaveChart(props: ModalSaveChartProps) {

  const [charts, setCharts] = useState([] as LibraryChart[])
  const [folder, setFolder] = useState('Miscellaneous')
  const [newFolder, setNewFolder] = useState('')
  const [chartDesc, setChartDesc] = useState(props.title)
  const [closeRequested, setCloseRequested] = useState(false)
  const [step, setStep] = useState(ApiStep.Confirm)
  const prefs = useContext(PrefsContext)

  const { customerDN, customerID, chartDefinition, chartSettings } = props
  const { customer } = prefs

  const folderChanged =  useCallback((e: any) => {
    if (e && e.target && e.target.value) {
      setFolder(e.target.value)
    }
  }, [setFolder])

  const newFolderChanged =  useCallback((input: string) => {
    setNewFolder(input)
  }, [setNewFolder])

  useEffect(() => {
      getLibraryChartsByCustomer(customer)
      .then((resp) => {
        if (resp && resp.length > 0) {
          setCharts(resp)
        }
      })
  }, [setCharts, customer])

  const chartID = useMemo((): string | undefined  => {
    if (!chartDesc) {
      return undefined
    }
    for (const chart of charts) {
      if (chart.description.trim() === chartDesc.trim()) {
        return chart.id
      }
    }
    return undefined
  }, [charts, chartDesc])

  const createChart = useCallback(() => {
    if (!customerID) {
      console.log('no customer selected, not saving shart')
      return
    }
    createLibraryChart(customerID, {
      description: chartDesc,
      definition: chartDefinition,
      settings: chartSettings,
      folder: newFolder || folder,
    })
    .then(() => {
      setStep(ApiStep.Done)
      setTimeout(() => {setCloseRequested(true)}, 1500)
    })
    .catch((e) => {
      setStep(ApiStep.Error)
      console.log('chart save failed', e)
    })
  }, [customerID, chartDesc, folder, chartDefinition, chartSettings, setStep, newFolder])

  const updateChart = useCallback(() => {
    if (!customerID || !chartID) {
      console.log('no customer selected, not saving shart')
      return
    }
    updateLibraryChart(customerID, chartID, {
      definition: chartDefinition,
      settings: chartSettings,
      folder: newFolder || folder,
    })
    .then(() => {
      setStep(ApiStep.Done)
      setTimeout(() => {setCloseRequested(true)}, 1500)
    })
    .catch((e) => {
      setStep(ApiStep.Error)
      console.log('chart save failed', e)
    })
  }, [customerID, folder, chartDefinition, chartSettings, setStep, chartID, newFolder])

  const canSave = useMemo(() => {
    if (folder === 'new' && (!newFolder || newFolder.toLowerCase().trim() === 'new')) {
      return false
    }
    if (folder && chartDesc && step === ApiStep.Confirm) {
      return true
    }
    return false
  }, [folder, newFolder, chartDesc, step])

  const buttonConfigs = useMemo(() => {
    return (
      [{
        title: 'Save Chart',
        disabled: !canSave,
        onClick: chartID ? updateChart : createChart,
        className: 'me-3',
      }] as ButtonConfig[]
    )
  }, [canSave, createChart, chartID, updateChart])

  return (
    <ModalBasic
      closer={props.closer}
      closeLabel={step === ApiStep.Error ? 'Close' : 'Cancel'}
      headerText="Save Chart to Library"
      headerIcon={faChartArea}
      sz="sm"
      buttonConfigs={buttonConfigs}
      closeRequested={closeRequested}
    >
      <div className="modal-save-chart">

        {step === ApiStep.Confirm && <>
        <TextInputRow
          label="Chart Name"
          labelClass="width-95"
          onChange={setChartDesc}
          defaultValue={chartDesc}
        />
        <FolderSelector
          label="Folder"
          labelClass="width-95"
          onChange={folderChanged}
          defaultValue={folder}
          showAddNew={true}
          charts={charts}
        />
        {folder === 'new' &&
          <TextInputRow
            label="New Folder"
            labelClass="width-95"
            onChange={newFolderChanged}
          />
        }
        <hr className="mt-3"/>
        <div className="modal-save-chart-body">
        {chartID && <>
          <div className="caution">
            Saving will update the existing <b>{chartDesc}</b> Chart in your Library. Name this Chart something else to create a new one.
          </div>
          <hr className="mt-3"/>
          </>
        }
          <div className="subtle">
            <span className="bold me-1">Note:</span>
            Saved Charts are accessible to anyone at {customerDN}.
          </div>
        </div>
        </>}

        {step === ApiStep.InProgress &&
        <StatusBanner
          isLarge={true}
          label="Working..."
          className="chart-save-in-progress"
          status={GeneralStatus.Muted}
        />}

        {step === ApiStep.Done &&
        <StatusBanner
          isLarge={true}
          label="Chart Successfully Saved"
          className="chart-save-success"
          status={GeneralStatus.OK}
        />}

        {step === ApiStep.Error && <>
          <StatusBanner
            isLarge={true}
            label="Chart Save Failed"
            status={GeneralStatus.Failure}
            className="chart-save-failure"
          />
          <div className="chart-save-failed-content">
            <span>Your Chart was not saved due to a system error. We're really sorry about this! Please contact your Agrology for further assistance.</span>
          </div>
        </>}

      </div>
    </ModalBasic>
  )
}