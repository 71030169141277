import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GetLocalizedTextItem } from '../../../localization/localization'
import { PrefsContext } from '../../../prefs/PrefsContext'

import ButtonBasic from '../Buttons/ButtonBasic'

import './ModalBasic.css'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { GeneralStatus } from '../../../types/types'
import { faCheck, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export interface ButtonConfig {
  title: string
  onClick: any
  className: string
  disabled?: boolean
  color?: string
}

interface ModalBasicProps {
  scrollable?: boolean,
  sz?: string,
  closer?: any,
  closeHref?: string,
  headerContent?: any,
  headerText?: string,
  headerIcon?: any,
  bodyText?: string,
  closeLabel?: string,
  closeButtonClass?: string,
  wideCloseButton?: boolean,
  hideClose?: boolean,
  hideFooter?: boolean,
  footerClass?: string,
  buttons?: any
  buttonConfigs?: ButtonConfig[]
  children?: any
  closeable?: boolean
  stackButtons?: boolean
  closeRequested?: boolean
  showRequiredFieldsNote?: boolean
  yOffsetPx?: number
  useXCloser?: boolean
  spreadButtons?: boolean
}

export default function ModalBasic(props: ModalBasicProps) {

  const [isClosing, setIsClosing] = useState(false)
  const { language } = useContext(PrefsContext)

  const { scrollable, sz, wideCloseButton, closeButtonClass, footerClass,
    stackButtons, closeRequested, closeable, closer, closeHref, spreadButtons,
    yOffsetPx } = props

  const close = useCallback(() => {
    if (closeable === false) {
      return
    }
    setIsClosing(true)
    setTimeout(closer, 195)
  }, [setIsClosing, closeable, closer])

  const scrollableClass = useMemo(() => {
    if (scrollable) {
      return 'scrollable-modal'
    }
    return ''
  }, [scrollable])

  const wrapperClass = useMemo(() => {
    if (sz === 'fs') {
      return 'basic-modal-wrapper-fs'
    }
    return 'basic-modal-wrapper'
  }, [sz])

  const modalMaskClass = useMemo(() => {
    const base = 'basic-modal-mask'
    if (isClosing) {
      return `${base} basic-modal-closing`
    }
    return base
  }, [isClosing])

  const modalClass = useMemo(() => {
    let classes = 'basic-modal-container'
    if (isClosing) {
      classes = `${classes} basic-modal-closing`
    }
    if (sz === 'sm') {
      return `${classes} basic-modal-sm`
    }
    if (sz === '475') {
      return `${classes} basic-modal-475`
    }
    if (sz === 'smmd') {
      return `${classes} basic-modal-smmd`
    }
    if (sz === 'lg') {
      return `${classes} basic-modal-lg`
    }
    if (sz === 'xl') {
      return `${classes} basic-modal-xl`
    }
    if (sz === 'fs') {
      return `${classes} basic-modal-fs`
    }
    return `${classes} basic-modal-md`
  }, [isClosing, sz])

  const computedCloseButtonClass = useMemo(() => {
    if (wideCloseButton) {
      return `${closeButtonClass} wide`
    }
    return closeButtonClass
  }, [wideCloseButton, closeButtonClass])

  const computedFooterClass = useMemo(() => {
    let base = 'basic-modal-footer'
    if (stackButtons) {
      base = `${base} basic-modal-footer-stacked`
    } else if (spreadButtons) {
      base = `${base} basic-modal-footer-spreaded`
    }
    if (footerClass) {
      return `${base} ${footerClass}`
    }
    return base
  }, [footerClass, stackButtons, spreadButtons])

  const handleKeyPress = (e: any) => {
    if (closer && e.code === 'Escape') {
      close()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
    document.removeEventListener('keydown', handleKeyPress)
    }
  })

  useEffect(() => {
      if (closeRequested && closeable !== false) {
      close()
    }
  }, [closeRequested, close, closeable])

  const yPadding = useMemo(() => {
    if (yOffsetPx !== undefined) {
      return `${yOffsetPx}px`
    }
    return '60px'
  }, [yOffsetPx])

  return (
    <div className={modalMaskClass}>
      <div className={wrapperClass} style={{paddingTop: yPadding}}>
        <div className={modalClass}>
          <div className="basic-modal-header">
            {props.headerIcon &&
              <FontAwesomeIcon
                icon={props.headerIcon}
                className="basic-modal-header-icon"
              />
            }
            {props.headerText &&
              <h5>
                {props.headerText}
              </h5>
            }
            {props.headerContent && !props.headerText &&
              <props.headerContent />
            }
            {props.useXCloser && !props.hideClose && props.closer && props.closeable !== false &&
            <div className="basic-modal-x-closer" onClick={close}>
              <FontAwesomeIcon icon={faTimes} className="basic-modal-x-closer-icon" />
            </div>
            }
          </div>
          <div className={scrollableClass}>
            {props.bodyText && !props.children &&
              <div className="basic-modal-body font-reduce-1">
                {props.bodyText}
              </div>
            }
            {props.children && !props.bodyText &&
              <div className="basic-modal-body font-reduce-1">
                {props.children}
              </div>
            }
            {!props.hideFooter && (!props.useXCloser || (props.buttonConfigs && props.buttonConfigs.length > 0)) &&
              <div className={computedFooterClass}>
                {props.showRequiredFieldsNote &&
                  <div className="basic-modal-required-note">
                    {GetLocalizedTextItem(language, 'requiredField', 'upperAllFirst')}
                  </div>
                }
                {props.buttonConfigs &&
                  props.buttonConfigs.map((button) => {
                    return (
                      <ButtonBasic
                        disabled={button.disabled}
                        key={button.title}
                        className={button.className}
                        label={button.title}
                        onClick={() => { if (button.onClick) { button.onClick() }}}
                        color={button.color}
                      />
                    )
                  })
                }
                {props.buttons &&
                  <props.buttons />
                }
                {!props.hideClose && !props.useXCloser && closer &&
                  <ButtonBasic
                    className={computedCloseButtonClass}
                    label={props.closeLabel || "Close"}
                    onClick={close}
                    disabled={props.closeable === false}
                  />
                }
                {!props.hideClose && !props.useXCloser && closeHref &&
                  <ButtonBasic
                    className={computedCloseButtonClass}
                    label={props.closeLabel || "Close"}
                    href={closeHref}
                    disabled={props.closeable === false}
                  />
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export function ModalColumnDivider() {
  return (
    <div className="modal-column-divider" />
  )
}

interface StatusBannerProps {
  icon?: IconProp
  isLarge?: boolean
  status?: GeneralStatus
  label?: string
  className?: string
  iconClassName?: string
  labelClassName?: string
  noIcon?: boolean
}

export function StatusBanner(props: StatusBannerProps) {

  const { icon, isLarge, status, label, className, iconClassName,
    labelClassName, noIcon } = props

  const classes = useMemo((): string => {
    const base = 'status-banner'
    let out = base
    if (isLarge) {
      out = `${out} ${base}-large`
    }
    if (status === GeneralStatus.OK) {
      out = `${out} ${base}-success`
    } else if (status === GeneralStatus.Failure) {
      out = `${out} ${base}-failed`
    } else if (status === GeneralStatus.Caution) {
      out = `${out} ${base}-caution`
    } else if (status === GeneralStatus.Working) {
      out = `${out} ${base}-working`
    } else if (status === GeneralStatus.Muted) {
      out = `${out} ${base}-muted`
    }
    if (className) {
      return `${out} ${className}`
    }
    return out
  }, [isLarge, status, className])

  const iconClasses = useMemo((): string => {
    const base = 'status-banner-icon'
    if (iconClassName) {
      return `${base} ${iconClassName}`
    }
    return base
  }, [iconClassName])

  const labelClasses = useMemo((): string => {
    const base = 'status-banner-label'
    if (labelClassName) {
      return `${base} ${labelClassName}`
    }
    return base
  }, [labelClassName])

  const statusIcon = useMemo((): IconProp | undefined => {
    if (noIcon) {
      return undefined
    }
    if (icon) {
      return icon
    }
    if (status === GeneralStatus.OK) {
      return faCheck
    } else if (status === GeneralStatus.Failure) {
      return faTimes
    } else if (status === GeneralStatus.Caution) {
      return faTriangleExclamation
    }
    return undefined
    }, [icon, status, noIcon])

  return  <div className={classes}>
    {statusIcon && <FontAwesomeIcon icon={statusIcon} className={iconClasses} />}
    {label && <div className={labelClasses}>{label}</div>}
  </div>
}