import { useState } from "react"
import ModalBasic from "../controls/modals/ModalBasic"
import { DefaultChartLineColorList, LoadStoredColors, StoreColors } from "./LineColors"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faPalette, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import ButtonBasic from "../controls/Buttons/ButtonBasic"

import './ModalEditChartColors.css'

interface EditableChartColorProps {
  label: string
  value: string
  onClickEdit?: any
  onClickForget?: any
}

function ChartColorForgetter(props: EditableChartColorProps) {
  return (
    <div className="chart-color-forgetter">
      <div className="chart-color-forgetter-prompt">
        Are you sure you want to forget this color?
      </div>

      <div className="editable-chart-color-entry-forget-row">
        <div className="editable-chart-color-swatch" style={{backgroundColor: props.value}} />
        <div className="editable-chart-color-entry-label">
          {props.label}
        </div>
      </div>

      <div className="editable-chart-color-entry-forget-buttons">
        <ButtonBasic color="red" label="Yes, Forget It" className="me-3" onClick={() => { props.onClickForget(props.label); props.onClickEdit() }} />
        <ButtonBasic label="Never Mind" onClick={() => {props.onClickEdit()}} />
      </div>

    </div>
  )
}

function ChartColorEditor(props: EditableChartColorProps) {

  const palette = DefaultChartLineColorList()

  const [currentColor, setCurrentColor] = useState(props.value || "")

  return (
    <div className="chart-color-editor">
      <div className="chart-color-editor-label">
        <div
            className="editable-chart-color-swatch-lg"
            style={{backgroundColor: props.value}}
        />
        <span>{props.label}</span>
      </div>
      <hr />
      <div className="chart-color-editor-palette">
        {palette.map(swatch => {
          if (currentColor === swatch.value) {
            return <div
            className="editable-chart-color-swatch-lg editable-chart-color-swatch-selected"
            style={{backgroundColor: swatch.value}}
            key={swatch.value}
            aria-label={swatch.name}
            title={swatch.name}
          />
          }
          return <div
            className="editable-chart-color-swatch-lg"
            style={{backgroundColor: swatch.value}}
            key={swatch.value}
            onClick={() => {setCurrentColor(swatch.value)}}
          />
        })}
      </div>
      <hr />
      <div className="editable-chart-color-entry-forget-buttons">
        <ButtonBasic label="Apply Selected Color" className="me-3" onClick={() => { props.onClickEdit(props.label, currentColor); props.onClickForget() }} />
        <ButtonBasic label="Cancel" onClick={() => {props.onClickForget()}} />
      </div>

    </div>
  )
}

function EditableChartColor(props: EditableChartColorProps) {
  return <div className="editable-chart-color-entry">
    <div className="editable-chart-color-swatch" style={{backgroundColor: props.value}} />
    <div className="editable-chart-color-entry-label">
      {props.label}
    </div>
    <div className="editable-chart-color-entry-edit" onClick={props.onClickEdit}>
      <FontAwesomeIcon icon={faEdit} />
    </div>
    {props.onClickForget && <div className="editable-chart-color-entry-delete" onClick={props.onClickForget}>
      <FontAwesomeIcon icon={faTrashAlt} />
    </div>}
  </div>
}

interface ModalEditChartColorsProps {
  closer?: any
  chartLabels?: string[]
}

export function ModalEditChartColors(props: ModalEditChartColorsProps) {

  const [editingColor, setEditingColor] = useState(undefined as string | undefined)
  const [forgettingColor, setForgettingColor] = useState(undefined as string | undefined)

  const colors = LoadStoredColors()
  const colorKeys = Object.keys(colors)
  colorKeys.sort()

  const deleteLabel = (label: string) => {
    delete colors[label]
    StoreColors(colors)
  }

  const updateLabelColor = (label: string, value: string) => {
    colors[label] = value
    StoreColors(colors)
  }


  const getRelevantLabels = (): string[] => {
    if (!props.chartLabels || props.chartLabels.length === 0) {
      return []
    }
    const out = [] as string[]
    for (const label of props.chartLabels) {
      if (colorKeys.includes(label)) {
        out.push(label)
      }
    }
    return out
  }

  const getIrrelevantLabels = (): string[] => {
    if (!props.chartLabels || props.chartLabels.length === 0) {
      return colorKeys
    }
    const out = [] as string[]
    for (const label of colorKeys) {
      if (!props.chartLabels.includes(label)) {
        out.push(label)
      }
    }
    return out
  }

  const relevantLabels = getRelevantLabels()
  const irrelevantLabels = getIrrelevantLabels()

  return (
    <ModalBasic
      headerText="Edit Chart Colors"
      sz="sm"
      closer={props.closer}
      closeLabel="Close"
      closeable={!forgettingColor && !editingColor}
      headerIcon={faPalette}
    >
      <div className="modal-edit-chart-colors-body">
        {forgettingColor &&
          <ChartColorForgetter
            label={forgettingColor}
            value={colors[forgettingColor]}
            onClickForget={deleteLabel}
            onClickEdit={() => {setForgettingColor(undefined)}}
          />
        }

        {editingColor && !forgettingColor &&
          <ChartColorEditor onClickForget={() => {setEditingColor(undefined)}} onClickEdit={updateLabelColor} label={editingColor} value={colors[editingColor]} />
        }

        {!editingColor && !forgettingColor && <div className="edit-chart-colors-list">
          {relevantLabels && relevantLabels.length > 0 &&
            <div className="edit-chart-colors-list-section-header">On This Chart</div>
          }
          {relevantLabels.map((label) => {
            return <EditableChartColor
              onClickEdit={() => {setEditingColor(label)}}
              label={label}
              value={colors[label]}
              key={label}
            />
          })}
          {irrelevantLabels && irrelevantLabels.length > 0 &&
            <div className="edit-chart-colors-list-section-header mt-3">Not On This Chart</div>
          }
          {irrelevantLabels.map((label) => {
            return <EditableChartColor
            onClickForget={() => {setForgettingColor(label)}}
            onClickEdit={() => {setEditingColor(label)}}
            label={label}
            value={colors[label]}
            key={label}
          />
          })}
          </div>
          }
      </div>
    </ModalBasic>
  )
}