export type SetBooleanFunc = (bool: boolean) => void

export enum GeneralStatus {
  OK,
  Working,
  Failure,
  Caution,
  Muted
}

export const emptyFunc = () => {}

export type NoParamsFunc = () => void

export type CloserFunc = NoParamsFunc

export interface BasicLookup {
    [key: string]: string | undefined | null
}

export interface LabelValuePair {
    value: string
    label: string
}

export type LabelValuePairs = LabelValuePair[]

export interface StringLookup {
    [key: string]: string
}

export interface NumberLookup {
    [key: number]: string | undefined | null
}
