import { useMemo } from 'react'
import CollapseMenu from '../controls/menus/CollapseMenu'
import { ItemClickedFunc, MenuItem } from '../controls/menus/MenuItem'

import './ChartMenu.css'

interface ChartMenuProps {
  showColorEditorFunc?: ItemClickedFunc
  saveChartToLibraryFunc?: ItemClickedFunc
  deleteFromLibraryFunc?: ItemClickedFunc
  moveChartToFolderFunc?: ItemClickedFunc
  openInBuilderFunc?: ItemClickedFunc
  downloadPNGFunc?: ItemClickedFunc
  downloadCSVFunc?: ItemClickedFunc
  canSaveToLibrary?: boolean
  canOpenInBuilder?: boolean
  canDeleteFromLibrary?: boolean
  canMoveFolders?: boolean
  hideDownloaders?: boolean
}

export function ChartMenu(props: ChartMenuProps) {

  const items = useMemo(() => {
    const out = [] as MenuItem[]
    if (props.canSaveToLibrary) {
      out.push(
        {
          label: 'Save Chart to Library',
          onClick: props.saveChartToLibraryFunc
        }
      )
    }
    if (props.canOpenInBuilder) {
      out.push(
        {
          label: 'Open Chart in Builder',
          onClick: props.openInBuilderFunc
        }
      )
    }
    if (!props.hideDownloaders) {
      out.push(
        {
          label: 'Edit Chart Colors',
          onClick: props.showColorEditorFunc
        }
      )
      out.push(
        {
          isSeparator: true
        }
      )
      out.push(
        {
          label: 'Download Chart as PNG',
          onClick: props.downloadPNGFunc
        }
      )
      out.push(
        {
          label: 'Download Chart Data as CSV',
          onClick: props.downloadCSVFunc
        }
      )
    }
    if (props.canMoveFolders) {
      out.push(
        {
          isSeparator: true
        }
      )
      out.push(
        {
          label: 'Move / Rename Chart',
          onClick: props.moveChartToFolderFunc
        }
      )
    }
    if (props.canDeleteFromLibrary) {
      out.push(
        {
          label: 'Delete this Chart',
          onClick: props.deleteFromLibraryFunc
        }
      )
    }
    return out
  }, [props])

  return <CollapseMenu
    headerLabel="Chart Options"
    className="chart-menu"
    items={items}
    posX={0}
  />
}