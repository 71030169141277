export function NoAccess () {
  return <>

    <div className="centered bold font-increase-3 mt-4">
      <br />
      <br />
      <img alt="Agrology Logo" src="/logo192.png" height="84" />
      <br />
      <br />
      <br />
      You do not have access to this resource.
      <br />
      <br />
      <br />
      Please contact Agrology for further assistance.
      </div>
  </>
}