import { useState, useContext, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PrefsContext } from '../../prefs/PrefsContext'

import { ReportDefinition, ReportRun } from '../../api/types'
import { getReportDefinitionsByCustomer, getReportRunsByCustomer } from '../../api/endpoints'
import { ModalEditGenerateReport } from './ModalEditGenerateReport'
import { ModalRunDetails } from './ModalRunDetails'
import Pillbox from '../controls/Pillbox/Pillbox'
import { Pill } from '../controls/Pillbox/pills'
import ButtonBasic from '../controls/Buttons/ButtonBasic'
import { RecentReportsPane } from './RecentReportsPane'
import { AllReportsPane } from './AllReportsPane'

import './ReportsPage.css'

const getDef = (id: string, defs?: ReportDefinition[]): ReportDefinition | undefined => {
  if (!id || !defs || defs.length === 0) {
    return undefined
  }
  for (const def of defs) {
    if (def.id === id) {
      return def
    }
  }
  return undefined
}

const getRun = (id: string, runs?: ReportRun[]): ReportRun | undefined => {
  if (!id || !runs || runs.length === 0) {
    return undefined
  }
  for (const run of runs) {
    if (run.id === id) {
      return run
    }
  }
  return undefined
}

export function ReportsPage() {

  const { customer } = useContext(PrefsContext)
  const { section, customerId, elementId } = useParams()
  const nav = useNavigate()

  const [tab, setTab] = useState(section === 'all' || section === 'settings' ? 1 : 0)
  const [reportDefs, setReportDefs] = useState([] as ReportDefinition[])
  const [recentRuns, setRecentRuns] = useState([] as ReportRun[])
  const [isLoading, setIsLoading] = useState(true)
  const [reloadRequested, setReloadRequested] = useState(Date.now())
  const [def, setDef] = useState(undefined as ReportDefinition | undefined)
  const [run, setRun] = useState(undefined as ReportRun | undefined)
  const [isEditorOpen, setIsEditorOpen] = useState(def ? true : false)
  const [isNewReportOpen, setIsNewReportOpen] = useState(false)
  const [isRunModalOpen, setIsRunModalOpen] = useState(false)

  const openEditor = useCallback((rd: ReportDefinition) => {
    setDef(rd)
    nav(`/reports/settings/${customer}/${rd.id}`, {replace: true})
    setIsEditorOpen(true)
  }, [customer, nav])

  const editorClosed = () => {
    requestReload()
    nav(`/reports/${tab !== 1 ? 'recent' : 'all'}`, {replace: true})
    setIsEditorOpen(false)
  }

  const openRunModal = useCallback((run?: ReportRun) => {
    if (!run) {
      return
    }
    setRun(run)
    nav(`/reports/generated/${customer}/${run.id}`, {replace: true})
    setIsRunModalOpen(true)
  }, [setRun, setIsRunModalOpen, nav, customer])

  const closer = () => {
    setIsNewReportOpen(false)
    requestReload()
  }

  const runModalClosed = () => {
    nav(`/reports/${tab !== 1 ? 'recent' : 'all'}`, {replace: true})
    setIsRunModalOpen(false)
  }

  const requestReload = (): void => {
    setReloadRequested(Date.now())
  }

  const tabChanged = (tab: number) => {
    if (tab === 1) {
      nav('/reports/all', {replace: true})
    } else {
      nav('/reports/recent', {replace: true})
    }
    setTab(tab)
  }

  useEffect(() => {
    const x = {
      c: customer,
      r: reloadRequested
    }
    getReportDefinitionsByCustomer(x.c)
    .then((resp: ReportDefinition[]) => {
      setReportDefs(resp)
    })
  }, [customer, reloadRequested])

  useEffect(() => {
    setIsLoading(true)
    getReportRunsByCustomer(customer)
    .then((resp: ReportRun[]) => {
      if (resp && resp.length > 0) {
        setRecentRuns(resp)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [customer, reloadRequested])

  useEffect(() => {
    if (customerId && elementId && section === 'settings') {
      const d = getDef(elementId, reportDefs)
      if (d) {
        setDef(d)
        setIsEditorOpen(true)
      }
    }
  }, [reportDefs, customerId, elementId, section])

  useEffect(() => {
    if (customerId && elementId && section === 'generated') {
      const r = getRun(elementId, recentRuns)
      if (r) {
        setRun(r)
        setIsRunModalOpen(true)
      }
    }
  }, [recentRuns, customerId, elementId, section])

  return <div className="reports-page">
    <div className="reports-page-nav">
    <Pillbox
      pills={[
        {caption: 'Recently Generated Reports', value: 'recent'},
        {caption: 'All Reports', value: 'all'}
      ] as Pill[]}
      tall={true}
      className="reports-page-pillbox"
      defaultValue={tab}
      onClick={tabChanged}
    />
    <ButtonBasic label="+ New Report" onClick={() => {setIsNewReportOpen(true)}} />
    </div>

    {tab !== 1 && <RecentReportsPane openEditorFunc={openEditor} openRunViewerFunc={openRunModal} defs={reportDefs} runs={recentRuns} isLoading={isLoading} requestReloadFunc={requestReload} />}
    {tab === 1 && <AllReportsPane openEditorFunc={openEditor} openRunViewerFunc={openRunModal} defs={reportDefs} runs={recentRuns} requestReloadFunc={requestReload} isLoading={isLoading} />}

    {isNewReportOpen && <ModalEditGenerateReport closer={closer} />}
    {isEditorOpen && def &&
      <ModalEditGenerateReport
        closer={editorClosed}
        reportDefinition={def}
      />
    }
    {isRunModalOpen && run &&
    <ModalRunDetails
      run={run || {} as ReportRun}
      def={run && run.def ? run.def : {} as ReportDefinition}
      closer={runModalClosed}
      requestReloadFunc={requestReload}
    />}



  </div>
}
