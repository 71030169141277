import { useCallback, useContext, useEffect, useMemo } from "react"
import { PrefsContext } from '../../prefs/PrefsContext'
import { ReportDefinition, ReportRun } from "../../api/types"
import { headerColumn, row, rowCell } from "../controls/Tables/tables"
import BasicTable from "../controls/Tables/BasicTable"
import { ago } from "../../time/relativeTime"
import { statusLabel } from "./schedule"
import { NoParamsFunc } from "../../types/types"
import { createReportRun } from "../../api/endpoints"

import './AllReportsPane.css'
import { faCog, faFileLines } from "@fortawesome/free-solid-svg-icons"

export type OpenEditorFunc = (rd: ReportDefinition) => void
export type OpenRunViewerFunc = (run: ReportRun) => void

export interface AllReportsPaneProps {
  defs?: ReportDefinition[]
  runs?: ReportRun[]
  isLoading: boolean
  openEditorFunc: OpenEditorFunc
  openRunViewerFunc: OpenRunViewerFunc
  requestReloadFunc: NoParamsFunc
}

const runStatusClass = (input: string): string => {
  if (input === 'success') {
    return 'report-run-success'
  }
  if (input === 'fail') {
    return 'report-run-failed'
  }
  return ''
}

const runFromDef = (def: ReportDefinition, runs?: ReportRun[]): ReportRun | undefined => {
  if (!runs || runs.length === 0 || !def || !def.id || !def.lastRan) {
    return undefined
  }
  for (const run of runs) {
    if (run.reportDefinitionID === def.id && run.ts === def.lastRan) {
      if (!run.def) {
        run.def = def
      }
      return run
    }
  }
  return undefined
}

export function AllReportsPane(props: AllReportsPaneProps) {
  const { defs, runs, requestReloadFunc, openEditorFunc, openRunViewerFunc, isLoading } = props
  const { customerAccess } = useContext(PrefsContext)

  const hasRunningReports = useMemo((): boolean => {
    if (!defs || defs.length === 0) {
      return false
    }
    for (const def of defs) {
      if (def && (['new', 'running'].includes(def.lastRanStatus || ''))) {
        return true
      }
    }
    return false
  }, [defs])

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasRunningReports && requestReloadFunc) { requestReloadFunc() }
    }, 3000);
    return () => clearInterval(interval);
  }, [hasRunningReports, requestReloadFunc]);

  const startRun = useCallback((def: ReportDefinition): void => {
    createReportRun(def.customerID, {reportDefinitionID: def.id} as ReportRun)
    .then(() => {
      if (requestReloadFunc) {
        requestReloadFunc()
      }
    })
  }, [requestReloadFunc])

  const customerDN = useMemo((): string => {
    if (!customerAccess || !customerAccess.displayName) {
      return 'Unknown Organization'
    }
    return customerAccess.displayName
  }, [customerAccess])

  const cols = [
    { text: 'Report Name' },
    { text: 'Last Generated' },
    { text: 'Last Status' },
    { text: 'Format' },
    { text: 'Email?' },
    { text: 'Scheduled?' },
    { text: '' }
  ] as headerColumn[]

  const rows = useMemo((): row[] => {
    const out = [] as row[]
    if (!defs || defs.length === 0) {
      return out
    }
    for (const def of defs) {
      const run = runFromDef(def, runs)
      out.push({
        cells: [
          {
            iconRight: faCog,
            value: def.description || '-',
            onClick: () => {openEditorFunc(def)}
          },
          {
            iconRight: run ? faFileLines : undefined,
            value: def.lastRan ? ago(def.lastRan) : '-',
            onClick: run ? () => {run.def = def ; openRunViewerFunc(run)} : undefined
          },
          {
            value: def.lastRanStatus ? statusLabel(def.lastRanStatus) : '-',
            className: runStatusClass(def.lastRanStatus || '')
          },
          {
            value: (def.reportFormat || '-').toUpperCase(),
          },
          {
            value: def.emailEnabled ? '✓' : '-',
            className: 'centered'
          },
          {
            value: ['weekly', 'daily', 'monthly'].includes(def.entryType) ? '✓' : '-',
            className: 'centered'
          },
          {
            value: 'Generate',
            onClick: () => {startRun(def)},
            isButton: true,
            buttonSize: 'sm',
            buttonOutline: true
          }
        ] as rowCell
      } as row)

    }
    return out
  }, [defs, runs, openEditorFunc, openRunViewerFunc, startRun])

  return <div className="reports-all-definitions-list">
    <BasicTable
      cols={cols}
      rows={rows}
      noDataMessage={`${customerDN} has no reports. Press '+ New Report' to generate one.`}
      isLoading={isLoading && (!rows || rows.length === 0)}
    />
</div>

}