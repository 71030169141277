import { ListBox } from "../controls/ListBox/ListBox"
import { LibraryChart } from "../../api/types"
import { useCallback, useMemo, useState } from "react"
import { item } from "../controls/ListBox/item"
import { faChartArea, faFolderClosed, faFolderOpen } from "@fortawesome/free-solid-svg-icons"

import './LibraryList.css'

interface LibraryListProps {
  chartSelectedFunc: any
  selectedChartID?: string
  charts?: LibraryChart[]
  isLoaded?: boolean
  isLoading?: boolean
  siteId?: string
}

const isChartInFolder = (id?: string, folder?: LibraryChart[]): boolean => {
  if (!id || !folder || folder.length ===0) {
    return false
  }
  for (const c of folder) {
    if (c.id === id) {
      return true
    }
  }
  return false
}

export default function LibraryList(props: LibraryListProps) {

  const [selectedFolderId, setSelectedFolderId] = useState('')

  const { charts, chartSelectedFunc, selectedChartID } = props

  const listBoxFolderClicked = useCallback((id: string) => {
    if (!id || (selectedFolderId && selectedFolderId === id)) {
      id = ''
    }
    setSelectedFolderId(id)
  }, [selectedFolderId, setSelectedFolderId])

  const folders = useMemo(() => {
    const out = [] as string[]
    if (!charts) {
      return out
    }
    for (const c of charts) {
      if (!(out.includes(c.folder))) {
        out.push(c.folder)
      }
    }
    out.sort()
    return out
  }, [charts])

  const chartsByFolder = useCallback((folder: string) => {
    const out = [] as LibraryChart[]
    if (!charts) {
      return out
    }
    for (const chart of charts) {
      if (chart.folder === folder) {
        out.push(chart)
      }
    }
    return out
  }, [charts])

  const items = useMemo(() => {
    const out = [] as item []
    if (!charts || !folders) {
      return out
    }

    for (const folder of folders) {
      const fc = chartsByFolder(folder)
      const id = `library-list-folder-${folder}}`
      const active = selectedFolderId === id || isChartInFolder(selectedChartID, fc)
      const folderItem = {
        id: id,
        items: [] as item[],
        title: folder,
        onClick: listBoxFolderClicked,
        icon: active ? faFolderOpen : faFolderClosed,
        isActive: active
      } as item
      if (folderItem.items) {
        for (const chart of fc) {
          folderItem.items.push({
            id: chart.id,
            title: chart.description,
            onClick: chartSelectedFunc,
            isActive: selectedChartID === chart.id,
            icon: faChartArea
          } as item)
        }
      }
      out.push(folderItem)
    }
    return out
  }, [charts, folders, chartSelectedFunc, listBoxFolderClicked, chartsByFolder, selectedChartID, selectedFolderId])


  return (
    <ListBox items={items} className="chart-library-list-box" isLoading={props.isLoading}  />
  )
}
