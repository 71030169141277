import { useMemo, useState } from 'react'
import './Pillbox.css'
import { Pill } from './pills'

type PillboxChangeFunc = (id: number) => void

interface PillboxProps {
  pills: Pill[]
  defaultValue?: number
  onClick?: PillboxChangeFunc
  className?: string
  tall?: boolean
}

export default function Pillbox(props: PillboxProps) {

  const { defaultValue, className, tall } = props

  const [selected, setSelected] = useState(defaultValue || 0)


  const onClick = (id: number) => {
    if (props.onClick) {
      props.onClick(id)
    }
    setSelected(id)
  }

  const classes = useMemo(() => {
    let base = 'pillbox-control'
    if (tall) {
      base = `${base} ${base}-tall`
    }
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className, tall] )

  return (
    <div className={classes}>
      {props.pills.map((pill, i) => {
        if (i === selected) {
          return <div
            className="pillbox-control-pill pillbox-control-pill-selected"
            key={pill.value}
          >
            <div>{pill.caption}</div>
          </div>
        } else {
          if (pill.href) {
            return <a
              className="pillbox-control-pill" key={pill.value}
              href={pill.href}
            >
            {pill.caption}
          </a>
          }
          return <div
            className="pillbox-control-pill" key={pill.value}
            onClick={() => {onClick(i)}}
          >
          <div>{pill.caption}</div>
        </div>
        }
      }
      )}
    </div>
  )
}
