import { useEffect, useMemo, useState } from "react"
import { TimeRanges } from "../../../time/timeRanges"
import { FormElement, FormElementCheckBox, FormElementDataType, FormElements,
  FormElementRadioGroup, FormDataLookup, FormElementSelect, FormElementTextArea,
  FormElementTextInput, FormElementTimePicker, FormElementToggleSwitch,
  FormElementType, supportedElementTypes,
  FormElementCheckBoxGroup,
  removeDeletes} from "./FormElement"
import TextArea from "../TextArea/TextArea"
import { RadioGroup } from "../Radios/RadioGroup"
import CheckBox, { CheckBoxProps } from "../CheckBox/CheckBox"
import ToggleSwitch from "../CheckBox/ToggleSwitch"
import Select from "../Selects/Select"
import TextInput from "../TextInput/TextInput"
import TimePicker from "../TimePicker/TimePicker"
import { Label } from "../Label/Label"
import { CheckBoxGroup } from "../CheckBox/CheckBoxGroup"

import './GeneralForm.css'

interface GeneralFormProps {
  elements: FormElements
  labelClass?: string
  inputClass?: string
  wrapperClass?: string
  setFormDatafunc: SetFormDatafunc
  setCanSubmitFunc: SetCanSubmitFunc
}
export type SetCanSubmitFunc = (canSubmit: boolean) => void
export type SetFormDatafunc = (formData: FormDataLookup) => void

const inputClasses = (formInputClass?: string,
  inputClass?: string): string | undefined => {
  if (formInputClass && inputClass) {
    return `${formInputClass} ${inputClass}`
  }
  if (formInputClass) {
    return formInputClass
  }
  if (inputClass) {
    return inputClass
  }
  return undefined
}

const valueFromElement = (el: FormElement,
  val: string | number | boolean | undefined): string | number | boolean | undefined => {
  if (el.dataType === FormElementDataType.Number) {
    if (typeof val === 'number') {
      return val
    }
    if (typeof val === 'string') {
      try {
        const num = parseFloat(val)
        if (isNaN(num)) {
          console.log('could not auto-load value:', val)
          return undefined
        }
        return num
      }
      catch {
        console.log('could not auto-load value:', val)
        return undefined
      }
    }
    if (typeof val === 'boolean') {
      return val ? 1 : 0
    }
  }
  if (el.dataType === FormElementDataType.Boolean) {
    if (typeof val === 'boolean') {
      return val
    }
    if (typeof val === 'number') {
      return val > 0
    }
    if (typeof val === 'string') {
      return val.toLowerCase() === 'true' || val.toLowerCase() === 't' || val === '1'
    }
  }
  return val
}

const formDataFromElements = (elements: FormElements): FormDataLookup => {
  const out = {} as FormDataLookup
  const valid = supportedElementTypes()
  for (const el of elements) {
    if (!(valid.includes(el.elementType))) {
      continue
    }
    if (!el.fieldName) {
      continue
    }
    if (el.defaultValue !== undefined) {
      const val = valueFromElement(el, el.defaultValue)
      if (val !== undefined) {
        out[el.fieldName] = val
        continue
      }
    }
    if (el.elementType === FormElementType.Select) {
      const sel = el as FormElementSelect
      if (sel && sel.items && sel.items.length > 0 && sel.items[0].value) {
        const val = valueFromElement(el, sel.items[0].value)
        if (val !== undefined) {
          out[el.fieldName] = val
        }
      }
    }
  }
  return out
}

export function GeneralForm(props: GeneralFormProps) {

  const { elements, setCanSubmitFunc, setFormDatafunc } = props
  const [formKeyPrefix] = useState(`form-${Date.now()}`)
  const [formData, setFormData] = useState(formDataFromElements(elements))

  const [submittedFormData, setSubmittedFormData] = useState('')

  useEffect(() => {
    const test = JSON.stringify(formData)
    if (test === submittedFormData) {
      return
    }
    setFormDatafunc(formData)
    removeDeletes(formData)
    setSubmittedFormData(JSON.stringify(formData))
  }, [formData, setFormDatafunc, submittedFormData, setSubmittedFormData])

  const canSubmit = useMemo(() => {
    for (const elem of elements) {
      if (!elem.required) {
        continue
      }
      if (!(elem.fieldName in formData)) {
        return false
      }
    }
    return true
  }, [elements, formData])

  useEffect(() => {
    setCanSubmitFunc(canSubmit)
  }, [canSubmit, setCanSubmitFunc])


  const validElements = useMemo(():FormElements => {
    const valid = supportedElementTypes()
    const out = [] as FormElements
    for (const el of elements) {
      if (!(valid.includes(el.elementType))) {
        continue
      }
      out.push(el)
    }
    return out
  }, [elements])

  const dataChanged = (el: FormElement, e: any) => {
    if (!el.fieldName || !e || !('target' in e) || !('value' in e.target)) {
      return
    }
    const out = {...formData}
    if (!e.target.value) {
      delete out[el.fieldName]
      out[`DELETE.${el.fieldName}`] = true
    } else {
      const val = valueFromElement(el, e.target.value)
      if (val === undefined) {
        out[`DELETE.${el.fieldName}`] = true
        delete out[el.fieldName]
      } else {
        out[el.fieldName] = val
      }
    }
    setFormData(out)
  }

  const checkBoxChanged = (el: FormElementCheckBox, e: any) => {
    if (!el.fieldName || !e || !('target' in e) || !('checked' in e.target)) {
      return
    }
    const elemVal = 'value' in e.target ? e.target.value : e.value
    const out = {...formData}
    if (!e.target.checked) {
      out[`DELETE.${el.fieldName}`] = true
      delete out[el.fieldName]
    } else {
      const val = valueFromElement(el, elemVal)
      if (val === undefined) {
        delete out[el.fieldName]
      } else {
        out[el.fieldName] = val
      }
    }
    setFormData(out)
  }

  const timeChanged = (el: FormElementTimePicker, tr: TimeRanges) => {
    if ('timeRange' in formData && formData.timeRange === tr.combined) {
      return
    }
    const out = {...formData}
    out[el.fieldName] = tr.combined
    setFormData(out)
  }

  return <>
    {validElements.map((el, i) => {
      if (el.elementType === FormElementType.TextInput) {
        const tel = el as FormElementTextInput
        return <TextInput
          label={el.label}
          labelClass={inputClasses(props.labelClass, el.labelClass)}
          className={inputClasses(props.inputClass, tel.inputClass)}
          wrapperClass={inputClasses(props.wrapperClass, el.wrapperClass)}
          required={el.required}
          defaultValue={tel.defaultValue ? tel.defaultValue.toString() : undefined}
          placeholder={tel.placeholder}
          disabled={el.disabled}
          id={el.id || el.fieldName}
          description={el.description}
          inputRef={el.inputRef}
          autoCapitalize={tel.autoCapitalize}
          autoComplete={tel.autoComplete}
          autoCorrect={tel.autoCorrect}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {dataChanged(el, e)}}
          key={`${formKeyPrefix}-${i}`}
        />
      }
      if (el.elementType === FormElementType.TextArea) {
        const tel = el as FormElementTextArea
        return <TextArea
          label={el.label}
          labelClass={inputClasses(props.labelClass, el.labelClass)}
          className={inputClasses(props.inputClass, tel.inputClass)}
          wrapperClass={inputClasses(props.wrapperClass, el.wrapperClass)}
          defaultValue={tel.defaultValue ? tel.defaultValue.toString() : undefined}
          placeholder={tel.placeholder}
          disabled={el.disabled}
          id={el.id || el.fieldName}
          description={el.description}
          inputRef={el.inputRef}
          autoCapitalize={tel.autoCapitalize}
          autoComplete={tel.autoComplete}
          autoCorrect={tel.autoCorrect}
          footnote={tel.footnote}
          key={`${formKeyPrefix}-${i}`}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {dataChanged(el, e)}}
          required={el.required}
          isError={tel.isError}
        />
      }
      if (el.elementType === FormElementType.Select) {
        const sel = el as FormElementSelect
        return <Select
          label={el.label}
          labelClass={inputClasses(props.labelClass, el.labelClass)}
          className={inputClasses(props.inputClass, sel.inputClass)}
          wrapperClass={inputClasses(props.wrapperClass, el.wrapperClass)}
          required={el.required}
          id={el.id || el.fieldName}
          defaultValue={sel.defaultValue}
          disabled={el.disabled}
          key={`${formKeyPrefix}-${i}`}
          items={sel.items}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {dataChanged(el, e)}}
        />
      }
      if (el.elementType === FormElementType.TimePicker) {
        const tel = el as FormElementTimePicker
        return <TimePicker
          timeChangeFunc={(e: any) => {timeChanged(tel, e)}}
          label={el.label}
          key={i}
          labelClass={inputClasses(props.labelClass, el.labelClass)}
          wrapperClass={inputClasses(props.wrapperClass, el.wrapperClass)}
          defaultValue={tel.timeRange}
        />
      }
      if (el.elementType === FormElementType.ToggleSwitch) {
        const tel = el as FormElementToggleSwitch
        return <ToggleSwitch
          label={el.label}
          labelClass={inputClasses(props.labelClass, el.labelClass)}
          className={props.inputClass}
          wrapperClass={inputClasses(props.wrapperClass, el.wrapperClass)}
          onClick={(e: any) => {checkBoxChanged(tel, e)} }
          value={tel.value}
          defaultChecked={tel.defaultChecked}
          disabled={el.disabled}
          id={el.id || el.fieldName}
          sz={tel.sz}
          key={`${formKeyPrefix}-${i}`}
        />
      }
      if (el.elementType === FormElementType.Checkbox) {
        const cel = el as FormElementCheckBox
        return <CheckBox
          label={el.label}
          labelClass={inputClasses(props.labelClass, el.labelClass)}
          className={props.inputClass}
          wrapperClass={inputClasses(props.wrapperClass, el.wrapperClass)}
          onClick={(e: any) => {checkBoxChanged(cel, e)} }
          value={cel.value}
          defaultChecked={cel.defaultChecked}
          disabled={el.disabled}
          id={el.id || el.fieldName}
          key={`${formKeyPrefix}-${i}`}
        />
      }
      if (el.elementType === FormElementType.CheckBoxGroup) {
        const cbg = el as FormElementCheckBoxGroup
        return <CheckBoxGroup
          label={el.label}
          labelClass={inputClasses(props.labelClass, el.labelClass)}
          className={props.inputClass}
          wrapperClass={inputClasses(props.wrapperClass, el.wrapperClass)}
          disabled={el.disabled}
          id={el.id || el.fieldName}
          key={`${formKeyPrefix}-${i}`}
          horizontal={cbg.horizontal}
          items={cbg.elements?.map((cel) => {
            return {
              label: cel.label,
              value: cel.value,
              defaultChecked: cel.defaultChecked,
              labelClass: cel.labelClass,
              disabled: cel.disabled,
              className: cel.className,
              wrapperClass: cel.wrapperClass,
              id: cel.id,
              onClick: (e: any) => {checkBoxChanged({ ...cel}, e)}
            } as CheckBoxProps
          })}
        />
      }
      if (el.elementType === FormElementType.Radio) {
        const rel = el as FormElementRadioGroup
        return <RadioGroup
          label={el.label}
          radioLabelClass={rel.radioLabelClass}
          radioWrapperClass={rel.radioWrapperClass}
          groupName={`${formKeyPrefix}-rg-${i}`}
          labelClass={inputClasses(props.labelClass, el.labelClass)}
          className={props.inputClass}
          wrapperClass={inputClasses(props.wrapperClass, el.wrapperClass)}
          onClick={(e: any) => {dataChanged(el, e)}}
          items={rel.items}
          defaultValue={rel.defaultValue ? rel.defaultValue.toString() : undefined}
          disabled={el.disabled}
          id={el.id || el.fieldName}
          key={`${formKeyPrefix}-${i}`}
          required={el.required}
        />
      }
      return <Label
        text={el.label}
        className={el.labelClass}
        key={`${formKeyPrefix}-${i}`}
        />
    })}

  </>
}
