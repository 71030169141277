import { ReportDefinition } from '../../api/types'
import { FormDataLookup } from '../controls/forms/FormElement'
import { StringLookup } from '../../types/types'
import { reportRanges } from './selectItems'

export enum ReportStep {
  SelectFormat,
  SetFormatParameters,
  SetReportTypeParameters,
  SetDeliveryParameters,
  SetScheduleParameters,
  SettingsSaved
}

export const CSV = 'csv'
export const PDF = 'pdf'
const CUSTOMER = 'customer'
const CUSTOM = 'custom'
const ALL = 'all'
const SITE = 'site'
const NODE = 'node'
const MANUAL = 'manual'
const MONTHLY = 'monthly'
const WEEKLY = 'weekly'
const DAILY = 'daily'

const DefaultTimestampFormat = 'humanReadable'
const DefaultTimeBucket = '1800'
const DefaultTimePeriod = 'custom'

const supportedTimePeriods = ['snl', 'ytd', 'mtd', 'lw', 'l7d', DefaultTimePeriod, 'lm', 'ly'] as string[]
const supportedTimestampFormats = [DefaultTimestampFormat, 'unix']
const supportedCSVTimeBuckets = ['1800', '3600', '43200', '86400', '604800', '2592000'] as string[]

export const formDataFromReportDef = (def: ReportDefinition): FormDataLookup => {
  const out = {} as FormDataLookup
  if ([MONTHLY, WEEKLY, DAILY].includes(def.entryType)) {
    out.generateRecurring = true
    out.cadence = def.entryType
    if (def.entryType === MONTHLY) {
      if (def.cadenceDayOfMonth) {
        out.cadenceDayOfMonth = def.cadenceDayOfMonth.toString()
      } else {
        out.cadenceDayOfMonth = '1'
      }
    } else if (def.entryType === WEEKLY) {
      if (def.cadenceDayOfWeek) {
        out.cadenceDayOfWeek = def.cadenceDayOfWeek.toString()
      } else {
        out.cadenceDayOfWeek = '1'
      }
    }
    if (def.cadenceHour !== undefined) {
      out.cadenceHour = def.cadenceHour.toString()
    } else {
      out.cadenceHour = '0'
    }
    if (def.cadenceMinute !== undefined) {
      out.cadenceMinute = def.cadenceMinute.toString()
    } else {
      out.cadenceMinute = '0'
    }
  } else {
    out.generateRecurring = false
    out.cadenceDayOfMonth = '1'
    out.cadenceDayOfWeek = '1'
    out.cadenceHour = '0'
    out.cadenceMinute = '0'
    out.cadence = 'weekly'
  }

  if (def.aggregateCharts) {
    out.chartCount = def.aggregateCharts
  }
  if (def.aggregateFiles) {
    out.fileCount = def.aggregateFiles
  }
  if (def.description) {
    out.reportName = def.description
  }
  if (def.reportFormat) {
    out.reportFormat = def.reportFormat
    if (def.metadata) {
      if (def.reportFormat === PDF) {
        if ('sections' in def.metadata && def.metadata.sections) {
          const sections = def.metadata.sections.split(',')
          for (const section of sections) {
            out[`section.${section}`] = true
          }
        }
        if ('folders' in def.metadata && def.metadata.folders) {
          const folders = def.metadata.folders.split(',')
          for (const folder of folders) {
            out[`folder.${folder}`] = true
          }
        }
      } else if (def.reportFormat === CSV) {
        if ('timestampFormat' in def.metadata && supportedTimestampFormats.includes(def.metadata.timestampFormat)) {
          out.timestampFormat = def.metadata.timestampFormat
        } else {
          out.timestampFormat = DefaultTimestampFormat
        }
        if ('timeBucket' in def.metadata && supportedCSVTimeBuckets.includes(def.metadata.timeBucket)) {
          out.timeBucket = def.metadata.timeBucket
        } else {
          out.timeBucket = DefaultTimeBucket
        }
        if ('metrics' in def.metadata && def.metadata.metrics) {
          const metrics = def.metadata.metrics.split(',')
          for (const metric of metrics) {
            out[`csvMetric.${metric}`] = true
          }
        }
      }
    }
  } else {
    out.reportFormat = PDF
  }
  if (def.scope === CUSTOMER) {
    out.siteId = ALL
  } else if (def.scope === SITE && def.targetID) {
    out.siteId = def.targetID
  } else if (def.scope === NODE && def.targetID) {
    const parts = def.targetID.split('.')
    if (parts.length === 2) {
      out.siteId = parts[0]
      out.nodeId = parts[1]
    }
  }
  const tr = def.timeRange || ''
  if (supportedTimePeriods.includes(tr)) {
    out.timePeriod = tr
  } else {
    out.timePeriod = CUSTOM
    out.timeRange = tr
  }
  if (def.emailEnabled) {
    let hasRecipients = false
    if (def.recipients && def.recipients.length > 0) {
      out.sendTo = def.recipients.join(',').toLowerCase().replaceAll(' ', '')
      hasRecipients = true
    }
    if (def.cc && def.cc.length > 0) {
      out.sendCC = def.cc.join(',').toLowerCase().replaceAll(' ', '')
      hasRecipients = true
    }
    if (def.bcc && def.bcc.length > 0) {
      out.sendBCC = def.bcc.join(',').toLowerCase().replaceAll(' ', '')
      hasRecipients = true
    }
    if (hasRecipients) {
      out.sendEmail = true
    }
  }

  return out
}

const asString = (input: string | number | boolean | undefined): string => {
  if (input === undefined) {
    return ''
  }
  if (typeof input === 'string') {
    return input
  }
  if (['number', 'boolean'].includes(typeof input)) {
    return input.toString()
  }
  return ''
}

const processTargets = (customerId: string, formData: FormDataLookup, def: ReportDefinition): void => {
  let siteId = asString(formData.siteId)
  let nodeId = asString(formData.nodeId)
  if (!siteId) { siteId = ALL }
  if (!nodeId) { nodeId = ALL }
  if (siteId === ALL) {
    def.scope = CUSTOMER
    def.targetID = customerId
    return
  }
  if (nodeId && nodeId !== ALL) {
    def.scope = NODE
    def.targetID = `${siteId}.${nodeId}`
    return
  }
  def.scope = SITE
  def.targetID = siteId
}

const processEmail = (formData: FormDataLookup, def: ReportDefinition): void => {
  if (formData.sendEmail === true) {
    def.emailEnabled = 1
    if (formData.sendTo) {
      def.recipients = asString(formData.sendTo).replaceAll(' ', '').toLowerCase().split(',')
      if (def.recipients.length === 0 || def.recipients[0] === '') {
        def.recipients = undefined
      }
    }
    if (formData.sendCC) {
      def.cc = asString(formData.sendCC).replaceAll(' ', '').toLowerCase().split(',')
      if (def.cc.length === 0 || def.cc[0] === '') {
        def.cc = undefined
      }
    }
    if (formData.sendBCC) {
      def.bcc = asString(formData.sendBCC).replaceAll(' ', '').toLowerCase().split(',')
      if (def.bcc.length === 0 || def.bcc[0] === '') {
        def.bcc = undefined
      }
    }
    def.emailEnabled = (def.recipients && def.recipients.length > 0) ||
      (def.cc && def.cc.length > 0) || (def.bcc && def.bcc.length > 0) ? 1 : 0
  }
  if (!def.emailEnabled) {
    def.emailEnabled = 0
  }
  if (!def.recipients) {
    def.recipients = []
  }
  if (!def.cc) {
    def.cc = []
  }
  if (!def.bcc) {
    def.bcc = []
  }
}

const processSchedule = (formData: FormDataLookup, def: ReportDefinition): void => {
  const generateRecurring = 'generateRecurring' in formData && formData.generateRecurring === true
  if (!generateRecurring) {
    def.entryType = MANUAL
    return
  }
  const cadence = asString(formData.cadence)
  if (!([WEEKLY, DAILY, MONTHLY].includes(cadence))) {
    def.entryType = MANUAL
    return
  }
  def.entryType = cadence
  const sHour = asString(formData.cadenceHour)
  const sMinute = asString(formData.cadenceMinute)
  if (!sHour || !sMinute) {
    def.entryType = MANUAL
    return
  }
  const hour = parseInt(sHour)
  const minute = parseInt(sMinute)
  if (cadence === MONTHLY) {
    const sDay = asString(formData.cadenceDayOfMonth)
    if (!sDay) {
    def.entryType = MANUAL
      return
    }
    const day = parseInt(sDay)
    def.cadenceDayOfMonth = day
    def.cadenceHour = hour
    def.cadenceMinute = minute
  } else if (cadence === WEEKLY) {
    const sDay = asString(formData.cadenceDayOfWeek)
    if (!sDay) {
    def.entryType = MANUAL
      return
    }
    const day = parseInt(sDay)
    def.cadenceDayOfWeek = day
    def.cadenceHour = hour
    def.cadenceMinute = minute
  } else if (cadence === DAILY) {
    def.cadenceHour = hour
    def.cadenceMinute = minute
  }
}

const processPDFSections = (formData: FormDataLookup, def: ReportDefinition): void => {
  const keys = Object.keys(formData)
  const metadata = {} as StringLookup
  const sections = [] as string[]
  const folders = [] as string[]
  for (const key of keys) {
    if (key.startsWith('section.') && formData[key] === true) {
      sections.push(key.replace('section.', ''))
    }
    if (key.startsWith('folder.') && formData[key] === true) {
      folders.push(key.replace('folder.', ''))
    }
  }
  if (sections.length === 0 && folders.length === 0) {
    return
  }
  if (sections.length > 0) {
    metadata.sections = sections.join(',')
  }
  if (folders.length > 0) {
    metadata.folders = folders.join(',')
  }
  if (!def.metadata) {
    def.metadata = metadata
  } else {
    Object.assign(def.metadata, metadata)
  }

  let chartCount = asString(formData.chartCount)
  let fileCount = asString(formData.fileCount)
  if (!chartCount) { chartCount = SITE }
  if (!fileCount) { fileCount = SITE}
  def.aggregateFiles = fileCount
  def.aggregateCharts = chartCount
}

const processFormat = (formData: FormDataLookup, def: ReportDefinition): void => {
  let reportFormat = asString(formData.reportFormat)
  if (!reportFormat) {
    reportFormat = PDF
  }
  def.reportFormat = reportFormat.toLowerCase()
}

const processTimeRange = (formData: FormDataLookup, def: ReportDefinition): void => {
  let tp = asString(formData.timePeriod)
  let found = false
  for (const period of reportRanges) {
    if (period.value === tp) {
      found = true
      break
    }
  }
  if (!found) {
    tp = DefaultTimePeriod
  }
  const tr = asString(formData.timeRange)
  if (tp === CUSTOM && tr) {
    def.timeRange = tr
  } else {
    def.timeRange = tp
  }
}

const processCsvMeta = (formData: FormDataLookup, def: ReportDefinition): void => {
  let tsf = asString(formData.timestampFormat)
  if (!supportedTimestampFormats.includes(tsf)) {
    tsf = DefaultTimestampFormat
  }
  let tsb = asString(formData.timeBucket)
  if (!supportedCSVTimeBuckets.includes(tsb)) {
    tsb = DefaultTimeBucket
  }

  const metadata = {timestampFormat: tsf, timeBucket: tsb} as StringLookup
  const keys = Object.keys(formData)
  const metrics = [] as string[]
  for (const key of keys) {
    if (key.startsWith('csvMetric.') && formData[key] === true) {
      metrics.push(key.replace('csvMetric.', ''))
    }
  }
  if (metrics.length === 0) {
    return
  }
  metadata.metrics = metrics.join(',')
  if (!def.metadata) {
    def.metadata = metadata
  } else {
    Object.assign(def.metadata, metadata)
  }
  let fileCount = asString(formData.fileCount)
  if (!fileCount) { fileCount = SITE}
  def.aggregateFiles = fileCount
}

export const reportDefFromFormData = (customerId: string, formData: FormDataLookup): ReportDefinition => {
  const def = {}  as ReportDefinition
  def.description = asString(formData.reportName)
  processTargets(customerId, formData, def)
  processEmail(formData, def)
  processSchedule(formData, def)
  processFormat(formData, def)
  processTimeRange(formData, def)
  if (def.reportFormat === PDF) {
    processPDFSections(formData, def)
  } else if (def.reportFormat === CSV) {
    processCsvMeta(formData, def)
  }
  return def
}

export const DefaultFormData = {
  siteId: ALL,
  nodeId: ALL,
  timePeriod: DefaultTimePeriod,
  chartCount: SITE,
  fileCount: SITE,
  'section.summary': true,
  'section.climate': true,
  'section.soil': true,
  'section.water': true,
  'section.airQuality': true,
  'section.co2AndGhg': true,
  generateNow: true,
  cadence: WEEKLY,
  cadenceDayOfMonth: '1',
  cadenceDayOfWeek: '1',
  cadenceHour: '0',
  cadenceMinute: '0',
  timestampFormat: DefaultTimestampFormat,
  timeRange: '4w-'
} as FormDataLookup