import { useState } from "react"
import ToggleSwitch from "../controls/CheckBox/ToggleSwitch"
import Select from "../controls/Selects/Select"

import './TimeBucketSelector.css'
import WhatsThis from "../controls/WhatsThis/WhatsThis"

type BucketSelectorChangedFunc = (useBucketing: boolean, size: number, operation: string) => void

interface TimeBucketSelectorProps {
  onChange?: BucketSelectorChangedFunc
  defaultBucketingOn?: boolean
  defaultBucketSize?: number
  defaultOperation?: string
}

export function TimeBucketSelector(props: TimeBucketSelectorProps) {

  const [bucketingOn, setBucketingOn] = useState(props.defaultBucketingOn || false as boolean)
  const [bucketSize, setBucketSize] = useState(props.defaultBucketSize || 0 as number)
  const [bucketingOp, setBucketingOp] = useState(props.defaultOperation || "avg" as string)

  const enabledChanged = (e: any) => {
    const isEnabled = e && e.target && e.target.checked
    setBucketingOn(isEnabled)
    let size = bucketSize
    if (isEnabled && bucketSize === 0) {
      size = 1800
      setBucketSize(1800)
    }
    if (props.onChange) {
      setTimeout(() => {if (props.onChange) { props.onChange(isEnabled, size, bucketingOp)}}, 210)
    }
  }

  const bucketSizeChanged = (e: any) => {
    let size = 0
    if (e && e.target && e.target.value) {
      try {
        size = parseInt(e.target.value)
      }
      catch {
        size = 0
      }
    }
    setBucketSize(size)
    if (props.onChange) {
      props.onChange(bucketingOn, size, bucketingOp)
    }
  }

  const bucketopChanged = (e: any) => {
    let op = "avg"
    if (e && e.target && e.target.value) {
      op = e.target.value
    }
    setBucketingOp(op)
    if (props.onChange) {
      props.onChange(bucketingOn, bucketSize, op)
    }
  }

  return (
    <div className="time-bucket-selector">

      <div className="time-bucket-selector-toggle-row">
        <span>Use Time Bucketing</span>
        <ToggleSwitch
          sz="sm"
          defaultChecked={props.defaultBucketingOn}
          onClick={enabledChanged}
        />
        <WhatsThis
          useHover={true}
          description="Time Bucketing aligns the chart data points to a common cadence instead of showing instantaneous data."
          className="time-bucket-whats-this"
        />
      </div>

      {bucketingOn &&
      <>
      <Select
        className="query-pane-select wide mb-2"
        label="Bucket Size"
        labelClass="time-bucket-selector-row-label"
        onChange={bucketSizeChanged}
        defaultValue={props.defaultBucketSize}
      >
        <option value="1800">30 Minutes</option>
        <option value="3600">1 Hour</option>
        <option value="43200">12 Hours</option>
        <option value="86400">1 Day</option>
        <option value="604800">1 Week</option>
        <option value="2592000">1 Month</option>
      </Select>

      <Select
      className="query-pane-select wide mb-2"
      label="Operation"
      labelClass="time-bucket-selector-row-label"
      onChange={bucketopChanged}
      defaultValue={props.defaultOperation}
      >
        <option value="avg">Average</option>
        <option value="runningTotal">Running Total</option>
        <option value="sum">Sum</option>
        <option value="min">Mininum Value</option>
        <option value="max">Maximum Value</option>
      </Select>
      </>
      }
    </div>
  )

}
