import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { PrefsContext } from "../../prefs/PrefsContext"
import { CustomerAccess, LibraryChart } from "../../api/types"
import { getLibraryChartsByCustomer } from "../../api/endpoints"
import { newUUID } from "../../uuid"
import { TimeRanges } from "../../time/timeRanges"

import LibraryChartBox from "./LibraryChartBox"
import LibraryConfig from "./LibraryConfig"

import './Library.css'

const getFirstSite = (access?: CustomerAccess): string | undefined => {
  if (!access || !access.sites) {
    return undefined
  }
  for (const key in access.sites) {
    return access.sites[key].id
  }
  return undefined
}

interface LibraryProps {
  className?: string
}

const getSelectedSite = (customer: string, customerAccess?: CustomerAccess): string | undefined => {
  if (!customer || !customerAccess) {
    return undefined
  }
  const key = `${customer}.selectedSite`
  let storedSite = localStorage.getItem(key) as string | undefined
  if (!storedSite || !(storedSite in customerAccess.sites)) {
    storedSite = getFirstSite(customerAccess)
    if (storedSite) {
      localStorage.setItem(key, storedSite)
    }
  }
  return storedSite
}

export default function Library(props: LibraryProps) {

  const prefs = useContext(PrefsContext)
  const {customer, setCustomerFunc, customerAccess} = prefs
  const {className} = props

  const { customerId, elementId } = useParams()

  const cust = customerId || customer
  if (cust && cust !== customer) {
    setCustomerFunc(cust)
  }

  if (elementId) {
    localStorage.setItem(`${customer}.selectedChartId`, elementId)
  }

  const [access, setAccess] = useState(customerAccess)
  const [chartRenderKey, setChartRenderKey] = useState(newUUID())
  const [configRenderKey, setConfigRenderKey] = useState(newUUID())
  const [charts, setCharts] = useState([] as LibraryChart[])
  const [chartId, setChartId] = useState(elementId || localStorage.getItem(`${customer}.selectedChartId`) || undefined)
  const [timeRange, setTimeRange] = useState(localStorage.getItem(`${customer}.selectedTimeRange`) || '24h-')
  const [selectedSite, setSelectedSite] = useState(getSelectedSite(customer, customerAccess))
  const [selectedNode, setSelectedNode] = useState( localStorage.getItem(`${customer}.selectedNode`) || undefined )
  const [isLoading, setIsLoading] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const nav = useNavigate()

  const siteName = useMemo(() => {
    if (!access || !access.sites || !selectedSite) {
      return
    }
    for (const id in access.sites) {
      if (access.sites[id].id === selectedSite) {
        return access.sites[id].displayName
      }
    }
    return ''
  }, [access, selectedSite])

  const displayedChartId = useMemo(() => {
    if (!customer) {
      return
    }
    if (chartId) {
      return chartId
    }
    if (!charts || charts.length === 0) {
      return undefined
    }
    for (const chart of charts) {
      if (chart.description === 'Canopy Temperature') {
        return chart.id
      }
    }
  }, [chartId, charts, customer])

  useEffect(() => {
    if (!customer) {
      return
    }
    const x = {
      c: charts,
      key: newUUID()
    }
    if ((window.location.pathname === '/charts' ||
      window.location.pathname.includes('library')) &&
      displayedChartId && !(window.location.href.includes(displayedChartId))) {
      nav(`/charts/library/${customer}/${displayedChartId}`, { replace: true })
    }
    setChartRenderKey(x.key)
  }, [elementId, displayedChartId, charts, customer, nav])

  const timeRangeChanged = (t: TimeRanges) => {
    if (t.startVal === 'now') {
      t.startSign = ''
    } else if (t.startMode === 'now') {
      t.startVal = 'now'
      t.startSign = ''
    }
    if (t.endVal === 'now' || t.endMode === 'now') {
      t.endSign = ''
      t.endVal = ''
    }
    const tr = `${t.startSign}${t.startVal}-${t.endSign}${t.endVal}`
    localStorage.setItem(`${customer}.selectedTimeRange`, tr)
    setTimeRange(tr)
  }

  const chartIdChanged = (id?: string) => {
    if (!id) {
      return
    }
    localStorage.setItem(`${customer}.selectedChartId`, id)
    setChartId(id)
  }

  const siteChanged = (e: any) => {
    if (!e || !e.target || !('value' in e.target)) {
      return
    }
    const site = e.target.value
    localStorage.setItem(`${customer}.selectedSite`, site)
    setSelectedNode(undefined)
    setSelectedSite(site)
  }

  const nodeChanged = (e: any) => {
    if (!e || !e.target || !('value' in e.target)) {
      return
    }
    const node = e.target.value
    localStorage.setItem(`${customer}.selectedNode`, node)
    setSelectedNode(node)
  }

  useEffect(() => {
    setAccess(customerAccess)
    if (customer) {
      setSelectedSite(localStorage.getItem(`${customer}.selectedSite`) || getFirstSite(customerAccess))
      setSelectedNode(localStorage.getItem(`${customer}.selectedNode`) || undefined)
      setTimeRange(localStorage.getItem(`${customer}.selectedTimeRange`) || '24h-')
      setChartId(localStorage.getItem(`${customer}.selectedChartId`) || undefined)
      setChartRenderKey(newUUID())
      setConfigRenderKey(newUUID())
    }
  }, [customerAccess, customer])

  const loadLibraryCharts = useCallback(() => {
    if (!access){
      return
    }
    setIsLoading(true)
    getLibraryChartsByCustomer(access.customer)
    .then((resp) => {
      if (resp && resp.length > 0) {
        setCharts(resp)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [access, setCharts, setIsLoading])

  useEffect(() => {
    loadLibraryCharts()
  }, [loadLibraryCharts, setIsLoaded])

  const libraryChart = useMemo(() => {
    if (!customer || !charts || charts.length === 0) {
      return undefined
    }
    if (!chartId) {
      for (const chart of charts) {
        if (chart.description === 'Canopy Temperature') {
          return chart
        }
      }
    }
    for (const chart of charts) {
      if (chart.id === chartId) {
        return chart
      }
    }
    return undefined
  }, [chartId, charts, customer])

  const classes = useMemo(() => {
    const base = 'chart-library-container'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  return (
    <div className={classes}>
      <LibraryConfig
        charts={charts}
        chartSelectedFunc={chartIdChanged}
        selectedChartID={displayedChartId}
        setTimeRangeFunc={timeRangeChanged}
        timeRange={timeRange}
        siteId={selectedSite}
        nodeId={selectedNode}
        onNodeChange={nodeChanged}
        onSiteChange={siteChanged}
        isLoading={isLoading}
        isLoaded={isLoaded}
        key={configRenderKey}
      />
      <LibraryChartBox
        chart={libraryChart}
        siteId={selectedSite}
        siteName={siteName}
        nodeId={selectedNode}
        key={chartRenderKey}
        timeRange={timeRange}
        setIsLoading={setIsLoading}
        setIsLoaded={setIsLoaded}
        libraryChartLoader={loadLibraryCharts}
      />
    </div>
  )
}
