import { useMemo } from "react"
import './CheckBox.css'

export interface CheckBoxProps {
  label?: string
  labelClass?: string
  onClick?: any
  value?: any
  defaultChecked?: boolean
  disabled?: boolean
  className?: string
  wrapperClass?: string
  id?: string | undefined
}

// the CheckBox is a simulated CheckBox element with additional capabilities
// e.g., nested sub-menus

export default function CheckBox(props: CheckBoxProps) {
  const { className, id, disabled, wrapperClass, labelClass } = props

  const checkboxId = useMemo(() => {
    if (id) {
      return id
    }
    return Math.random().toString()
  }, [id])

  const classes = useMemo(() => {
    const base = 'input-control input-control-checkbox'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const labelClasses = useMemo(() => {
    let base = 'input-control-label input-control-checkbox-label'
    if (disabled) {
      base = `${base} input-control-checkbox-label-disabled`
    }
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [disabled, labelClass])

  const wrapperClasses = useMemo(() => {
    const base = 'input-control-checkbox-container'
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  return (
    <label className={wrapperClasses} htmlFor={checkboxId}>
      <input
        type="checkbox"
        className={classes}
        disabled={props.disabled}
        id={checkboxId}
        value={props.value}
        defaultChecked={props.defaultChecked}
        onClick={props.onClick}
      />
      {props.label && <span className={labelClasses}>{props.label}</span>}
    </label>
  )
}
