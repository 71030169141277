import { CustomerAccess } from "../../api/types"
import { LabelValuePairs } from "../../types/types"

export interface SiteAndNodeOptionsInput {
  customerAccess?: CustomerAccess
  showAllSitesOption: boolean
  showSelectSiteOption: boolean
  hideNodeSelector: boolean
  showAllNodesOption: boolean
  showSelectNodeOption: boolean
  selectedSite?: string
  selectASiteLabel?: string
  selectANodeLabel?: string
  showAllSitesLabel?: string
  showAllNodesLabel?: string
}

export interface SiteAndNodeOptions extends SiteAndNodeOptionsInput{
  sites?: LabelValuePairs
  nodes?: LabelValuePairs
  disableNodeSelector?: boolean
}

export function siteAndNodeOptions(input: SiteAndNodeOptionsInput): SiteAndNodeOptions {
  const out = { ...input } as SiteAndNodeOptions
  if (!out.customerAccess) {
    return out
  }

  out.sites = [] as LabelValuePairs
  if (out.showSelectSiteOption) {
    out.sites.push({value: '', label: out.selectASiteLabel ? out.selectASiteLabel : '-- Select a Site--' })
  }
  if (out.showAllSitesOption) {
    out.sites.push({value: 'all', label: out.showAllSitesLabel ? out.showAllSitesLabel : 'All Sites' })
  }
  const siteKeys = Object.keys(out.customerAccess.sites)
  for (const key of siteKeys) {
    out.sites.push({label: out.customerAccess.sites[key].displayName, value: key})
  }

  if (!out.hideNodeSelector) {
    out.nodes = [] as LabelValuePairs
    if (out.showSelectNodeOption) {
      out.nodes.push({value: '', label: out.selectANodeLabel ? out.selectANodeLabel : '-- Select a Node --' })
    }
    if (out.showAllNodesOption) {
      out.nodes.push({value: 'all', label: out.showAllNodesLabel ? out.showAllNodesLabel : 'All Nodes' })
    }
    if ((out.selectedSite) && out.selectedSite in out.customerAccess.sites) {
      const siteAccess = out.customerAccess.sites[out.selectedSite]
      for (const node of siteAccess.nodes) {
        out.nodes.push({value: node.id, label: node.displayName})
      }
    } else {
      out.disableNodeSelector = true
    }
  }
  return out
}
