export enum ApiStep {
    Confirm,
    InProgress,
    Error,
    Done
  }

export interface CreatedResponse {
    status?: string
    id?: string
}

export interface ApiKeyAccessInfo {
    email?: string
}

export interface ApiKeyInfo {
    status?: string
    newKey?: string
}

export interface NodeInfo {
    displayName: string
    id: string
}

export interface NodeInfo {
    displayName: string
    id: string
    node: string
}

export interface SiteAccess {
    displayName: string
    id: string
    role: string
    nodes: NodeInfo[]
    units: string
}

export interface SiteAccessLookup {
    [key: string]: SiteAccess
}

export interface CustomerAccess {
    customer: string
    displayName: string
    sites: SiteAccessLookup
}

export interface UserAccess {
    appRole: string
    siteAccess: CustomerAccess[]
}

export interface Metric {
    displayName: string
    id: string
    units?: string
    category?: string
}

export interface MetricLookup {
    [key: string]: Metric
}

export interface MeasurementLookup {
    [key: string]: number
}

export interface Measurement {
    ts: number,
    dev: string,
    d: MeasurementLookup
}

export interface DeviceData {
    deviceType?: string,
    position?: string,
    id: string,
}

export interface DeviceDataLookup {
    [key: string]: DeviceData
}

export interface NodeData {
    name: string,
    id: string,
    siteId?: string,
    nodeId?: string,
    samples: [Measurement]
}

export interface NodeDataLookup {
    [key: string]: NodeData
}

export interface NodeData {
    name: string,
    id: string,
    siteId?: string,
}


export interface SiteData {
    id: string,
    name: string
}

export interface SiteDataLookup {
    [key: string]: SiteData
}

export interface APIResultRequestData {
    source?: string
    siteId?: string
    timeRange?: string
    format?: string
    responseVersion?: string
    metrics?: string[]
}

export interface APIResult {
    id: string
    status: string
    request?: APIResultRequestData
    code?: number
    location?: string
    sites?: SiteDataLookup
    nodes?: NodeDataLookup
    source?: string
}

export interface APIResultLookup {
    [key: string]: APIResult
}

export interface APISeriesMetadata {
    key: string
    label: string
    color: string
    visible: boolean
}

export interface APISeriesMetadataLookup {
    [key: string]: APISeriesMetadata[]
}

export interface DeviceInfo {
    id: string
    reportTime?: number
    reportingStatus?: string
    node?: string
    serial?: string
    position?: string
    deviceType?: string
}

export interface GenericMeasurementCollection {
    ts: number
    [key: string]: number
}

export interface SummaryElementData {
    name: string
    id: string
    site?: string
    siteId: string
    samples: [GenericMeasurementCollection]
}

export interface SummaryData {
    id?: string,
    status: string,
    metric?: Metric,
    nodes?: [SummaryElementData]
    source?: string
}

export interface ReportDefinition {
    id: string
    customerID: string
    aggregateCharts?: string
    aggregateFiles?: string
    bcc?: string[]
    cc?: string[]
    createdBy?: string
    description?: string
    emailEnabled?: number
    entryType: string
    metadata?: { [key: string]: string }
    cadenceDayOfMonth?: number
    cadenceDayOfWeek?: number
    cadenceHour?: number
    cadenceMinute?: number
    recipients?: string[]
    scope?: string
    targetID?: string
    templateID: string
    timeRange?: string
    lastRan?: number
    lastRanStatus?: string
    reportFormat?: string
}

export interface ReportDefinitionLookup {
    [key: string]: ReportDefinition
}

export interface ReportRun {
    id: string
    customerID: string
    ts: number
    status: string
    reportDefinitionID: string
    executionTimeMS: number
    files: string[]
    results: {[key: string]: any}
    lastModified?: number
    def?: ReportDefinition
}

export interface FileserverFile {
    url: string
    file: string
}

export interface LibraryChart {
    id: string
    customerID: string
    description: string
    definition: string
    settings: string
    folder: string
}

export interface FeedbackSubmission {
    client: string
    clientVersion: string
    appLocation: string
    customer: string
    feedbackType: string
    remarks: string
    wantsFollowUp?: boolean
    isTestMessage?: boolean
}
