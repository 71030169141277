import { ReportRun } from "../../api/types"

export const runStatusLabel = (run: ReportRun): string => {
  if (run.status === 'new') {
    return 'New'
  }
  if (run.status === 'success') {
    return '✓ Success'
  }
  if (run.status === 'fail') {
    return '× Failed'
  }
  return '?'
}

export const statusLabel = (input?: string): string => {
  if (!input) {
    return '-'
  }
  if (input === 'new') {
    return 'New'
  }
  if (input === 'success') {
    return '✓ Success'
  }
  if (input === 'fail') {
    return '× Failed'
  }
  return '-'
}
