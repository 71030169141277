import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { createContext, useState } from "react"

export interface IFlashMessageContext {
  message: string
  type: string
  duration?: number
  icon?: IconDefinition
  isClosing: boolean
  setFlashMessage: any
  clearFlashMessage: any
}

interface IPrefsProviderProps {
  children: React.ReactNode
}

export function defaultFlash() {
  return {
    message: '',
    duration: 0,
    type: '',
    isClosing: false,
    icon: undefined
  } as IFlashMessageContext
}

export const FlashMessageContext = createContext<IFlashMessageContext>(defaultFlash())

export const FlashMessageProvider = ({ children }: IPrefsProviderProps) => {

  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('')
  const [duration, setDuration] = useState(0 as number | undefined)
  const [isClosing, setIsClosing] = useState(false)
  const [icon, setIcon] = useState(undefined as IconDefinition | undefined)

  const value = {
    message: message,
    type: messageType,
    duration: duration,
    isClosing: isClosing,
    icon: icon,
    setFlashMessage : (message: string, type: string, duration: number = 0, icon?: IconDefinition) => {
      setMessage(message)
      setMessageType(type)
      setDuration(duration)
      if (icon) {
        setIcon(icon)
      }
      setIsClosing(false)
    },
    clearFlashMessage: () => {
      setIsClosing(true)
      setTimeout(() => {
        setMessage('')
        setMessageType('')
        setIcon(undefined)
        setDuration(0)
      }, 340)
    }
  } as IFlashMessageContext

  return <FlashMessageContext.Provider value={value}>{children}</FlashMessageContext.Provider>

}
