import { LabelValuePairs } from "../../types/types"

export const supportedCSVMetrics = [
  {
    value: "airTemp",
    label: "Canopy Temperature"
  },
  {
    value: "humidity",
    label: "Humidity"
  },
  {
    value: "barometricPressure",
    label: "Barometric Pressure"
  },
  {
    value: "dewPoint",
    label: "Dew Point"
  },
  {
    value: "vpd",
    label: "Vapor Pressure Deficit"
  },
  {
    value: "totalVOCs",
    label: "VOCs"
  },
  {
    value: "soilMoisture",
    label: "Soil Moisture"
  },
  {
    value: "soilTemperature",
    label: "Soil Temperature"
  },
  {
    value: "vwc",
    label: "Volumetric Water Content"
  },
  {
    value: "soilRespirationIndex",
    label: "Soil Respiration Index"
  },
  {
    value: "co2Concentration",
    label: "CO2 Concentration"
  },
  {
    value: "carbonFlux",
    label: "Carbon Flux"
  },
  {
    value: "cfluxDailyPos",
    label: "Carbon Flux Daily (Positive)"
  },
  {
    value: "smbc",
    label: "Soil Microbial Biomass Carbon"
  },
  {
    value: "n2oConcentration",
    label: "N2O Concentration"
  },
  {
    value: "nitrousFlux",
    label: "Nitrous Flux"
  },

] as LabelValuePairs
