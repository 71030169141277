import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FlashMessageContext } from './FlashMessageContext'

import './FlashMessage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const COUNTDOWN_INTERVAL = 50

interface DismisserProps {
  duration: number
  remaining: number
  elapsed: number
  closer: any
  isHovering: boolean
}

function Dismisser(props: DismisserProps) {

  const {remaining, duration, closer, elapsed, isHovering} = props

  const remainingSecs = useMemo(() => {
    if (remaining <= 0) {
      return 0
    }
    return Math.trunc(remaining / 1000) + 1
  }, [remaining])

  useEffect(() => {
    if (remaining <= 0 && elapsed > 0 && closer) {
      setTimeout(closer, COUNTDOWN_INTERVAL)
    }
  }, [remaining, closer, elapsed])

  if (duration && !isHovering) {
    return (
      <div className="flash-message-closer" onClick={closer}>
        <div className="flash-message-close-button">
          {remainingSecs}
        </div>
      </div>
    )
  }
  return (
    <div className="flash-message-closer" onClick={closer}>
      <div className="flash-message-close-button">
        <FontAwesomeIcon icon={faXmark} style={{ marginTop: "3px" }} />
      </div>
    </div>
  )
}

export default function FlashMessage() {

  const flash = useContext(FlashMessageContext)
  const { message, type, duration, isClosing, icon } = flash
  const [remaining, setRemaining] = useState(0)
  const [elapsed, setElapsed] = useState(0)
  const [isHovering, setIsHovering] = useState(false)

  const classes = useMemo(() => {
    let base = 'flash-message-container'
    if (isClosing) {
      base = `${base} flash-message-container-closing`
    }
    if (type === 'error') {
      return `${base} flash-message-container-error`
    }
    if (type === 'warning') {
      return `${base} flash-message-container-warning`
    }
    return base
  }, [isClosing, type])

  const updateRemaining = useCallback(() => {
    if (isHovering) {
      return
    }
    setRemaining((oldRemaining) => {
      return oldRemaining - COUNTDOWN_INTERVAL
    })
  }, [setRemaining, isHovering])

  useEffect(() => {
    if (isHovering) {
      return
    }
    if (remaining > 0) {
      setTimeout(updateRemaining, COUNTDOWN_INTERVAL)
      setElapsed((e) => {
        return e + COUNTDOWN_INTERVAL
      })
    } else {
      setElapsed(0)
    }
  }, [remaining, updateRemaining, setElapsed, isHovering])

  useEffect(() => {
    if (duration && duration > 0) {
      setElapsed(0)
      setRemaining(duration - 1)
    }
  }, [duration, setRemaining, setElapsed])

  useEffect(() => {
    const data = {
      d: duration,
      m: message,
      t: type,
      h: false
    }
    setIsHovering(data.h)
  }, [duration, message, type, setIsHovering])

  const onMouseEnter = () => {
    setIsHovering(true)
  }

  const onMouseLeave = () => {
    setIsHovering(false)
  }

  if (!message) {
    return <></>
  }

  return (
    <div className={classes} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="flash-message-inner">
        <div className="flash-message-body">
          {icon &&
            <div className="flash-icon mt-1">
              <FontAwesomeIcon
                icon={icon}
                className="flash-icon-svg me-2"
              />
            </div>
          }
          <div>{message}</div>
        </div>
        <div className="flash-dismiss">
          <Dismisser
            duration={duration || 0}
            closer={flash.clearFlashMessage}
            remaining={remaining}
            elapsed={elapsed}
            isHovering={isHovering}
          />
        </div>
      </div>
    </div>
  )
}