import { useMemo } from "react"
import './Radio.css'

interface RadioProps {
  label?: string
  value?: string
  id?: string
  name?: string
  defaultChecked?: boolean
  className?: string
  labelClass?: string
  wrapperClass?: string
  onClick?: any
  disabled?: boolean
}

export default function Radio(props: RadioProps) {

  const { className, labelClass, wrapperClass } = props

  const wrapperClasses = useMemo(() => {
    const base = 'radio-wrapper'
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  const classes = useMemo(() => {
    const base = 'input-control'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const labelClasses = useMemo(() => {
    const base = 'radio-label'
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [labelClass])

  return (
    <>
      <label className={wrapperClasses} onClick={props.onClick} htmlFor={props.id}>
        <div className="radio-wrapper-hover-area" onClick={props.onClick}>
          <input
            type="radio"
            defaultChecked={props.defaultChecked}
            name={props.name}
            id={props.id}
            className={classes}
            value={props.value}
            disabled={props.disabled}
            // onClick={props.onChange}
          />
          {props.label && <span className={labelClasses}>
          {props.label}</span>}
        </div>
      </label>
    </>
  )
}
