import { useMemo } from 'react'
import CheckBox, { CheckBoxProps } from './CheckBox'

import './CheckBoxGroup.css'

interface CheckBoxGroupProps {
  label?: string
  onClick?: any
  className?: string
  labelClass?: string
  wrapperClass?: string
  items?: CheckBoxProps[]
  disabled?: boolean
  id?: string
  required?: boolean
  horizontal?: boolean
}

export function CheckBoxGroup(props: CheckBoxGroupProps) {

  const { labelClass, wrapperClass, disabled, required, horizontal } = props

  const wrapperClasses = useMemo(() => {
    let base = 'checkbox-group'
    if (horizontal) {
      base = `${base} ${base}-wrap`
    }
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass, horizontal])

  const labelClasses = useMemo(() => {
    let base = 'checkbox-group-label'
    if (disabled) {
      base = `${base} disabled`
    }
    if (required) {
      base = `${base} required`
    }
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [labelClass, disabled, required])

  return (
    <div className={wrapperClasses}>
      {props.label && <div className={labelClasses}>
        {props.label}:
      </div>}
        {props.items && props.items.length > 0 &&
          props.items.map((val, i) => {
            return (
              <CheckBox
                onClick={val.onClick}
                value={val.value}
                label={val.label}
                key={`checkbox-key-${i}-${val.value}`}
                id={val.id}
                className={val.className}
                labelClass={val.labelClass}
                wrapperClass={val.wrapperClass}
                disabled={val.disabled}
              />
            )
          })
        }
    </div>
  )
}
