import { useContext, useEffect, useMemo, useState } from 'react'

import { useAuth } from "../../../auth/provider"
import { PrefsContext } from '../../../prefs/PrefsContext'
import { getAccess } from '../../../api/endpoints'
import { LabelValuePair, LabelValuePairs } from '../../../types/types'

import { SettingsMenu } from '../../SettingsMenu/SettingsMenu'
import { Wordmark } from '../../controls/svg/Wordmark'
import { RightHeader } from './RightHeader'
import Select from '../../controls/Selects/Select'

import './AppHeader.css'

function CustomerSelector() {
  const {customer, allAccess, setAllAccessFunc} =
  useContext(PrefsContext)
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    getAccess()
      .then((resp: any) => {
        if (setAllAccessFunc) {
          setAllAccessFunc(resp)
        }
      })
      .catch((e: any) => {
        console.log('ERROR', e)
      })
  }, [isAuthenticated, setAllAccessFunc])

  const changeCustomer = (cn: string) => {
    window.location.replace(`/customer/${cn}?from=${window.location.pathname}`)
  }

  const customerOptions = useMemo((): LabelValuePairs => {
    const out = [] as LabelValuePairs
    if (allAccess && allAccess.siteAccess && allAccess.siteAccess.length > 0) {
      for (const access of allAccess.siteAccess) {
        out.push({value: access.customer,
          label: access.displayName} as LabelValuePair)
      }
    }
    return out
  }, [allAccess])

  if (!isAuthenticated || !allAccess || !allAccess.siteAccess ||
    allAccess.siteAccess.length === 0) {
    return <></>
  }

  if (allAccess && allAccess.siteAccess && allAccess.siteAccess.length === 1) {
    return (
      <div>
        <span className="d-sm-none ms-2"></span>
        <span className="d-none d-sm-inline customer-selector ms-1 me-3">
          /
        </span>
        <span className="customer-selector">
          {allAccess.siteAccess[0].displayName}
        </span>
      </div>
    )
  }

  return (
    <Select
      className="customer-selector"
      key={customer}
      onChange={(e: any) => {changeCustomer(e.target.value)}}
      defaultValue={customer}
      items={customerOptions}
    />
  )
}

function Navbar() {

  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [settingsMenuX, setSettingsMenuX] = useState(0)

  const toggleMenu = () => {
    if (!isMenuVisible) {
      showMenu()
    } else {
      hideMenu()
    }
  }

  const hideMenu = () => {
    if (isMenuVisible) {
      setIsMenuVisible(false)
    }
    document.removeEventListener('click', hideMenu)
  }

  const showMenu = () => {
    if (!isMenuVisible) {
      setIsMenuVisible(true)
      setTimeout(addListener, 100)
    }
  }

  const addListener = () => {
    document.addEventListener('click', hideMenu)
  }

  useEffect(() => {
    const settingsMenuPositionLoop = () => {
      const icon = document.getElementById('settingsIcon')
      var left = 5
      if (icon) {
        const rect = icon.getBoundingClientRect()
        left = Math.floor(rect.x - 240)
        if (left < 5) {
          left = 5
        }
      }
      if (left !== settingsMenuX) {
        setSettingsMenuX(left)
      }
    }
    const interval = setInterval(settingsMenuPositionLoop, 250)
    return () => clearInterval(interval)
  }, [settingsMenuX])

  const np = window.location.hostname !== process.env.REACT_APP_prod_hostname &&
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== '127.0.0.1'

  return (
    <header>
      <div className="nav-header">
        <div className="nav-container">
          <nav className="navbar">
            <div className="nav-inner">
              <div className="nav-logos">
                <img alt="Agrology Logo" src="/logo192.png" className="logo" />
                <div className="d-none d-sm-block">
                  <Wordmark />
                </div>
                <CustomerSelector />
                {np && <div className="ms-3 non-public-banner">
                  NON-PUBLIC INSTANCE
                </div>}
              </div>
              <RightHeader
                toggleMenu={toggleMenu}
                isMenuVisible={isMenuVisible}
              />
            </div>
          </nav>
        </div>
      </div>
      {isMenuVisible && <SettingsMenu
        posX={settingsMenuX}
        closer={hideMenu} />}
    </header>
  )
}

export default Navbar
