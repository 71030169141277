import { useMemo, useState } from "react"
import './ButtonGroup.css'
import '../Buttons/ButtonBasic.css'
import { Link } from "react-router-dom"

interface ButtonGroupProps {
  groupName: string
  buttons: ButtonConfig[]
  defaultValue?: string | boolean | number
  label?: string
  onClick?: any
  buttonClass?: string
  buttonColor?: string
  buttonSize?: string
  className?: string
}

export interface ButtonConfig {
  caption: string
  value: string | boolean | number
  href?: string
}

export function ButtonGroup(props: ButtonGroupProps) {

  const [selection, setSelection] = useState(props.defaultValue)
  const { buttonColor, buttonSize, className } = props

  const btnColor = useMemo(() => {
    if (!buttonColor) {
        return 'black'
    }
    return buttonColor
  }, [buttonColor])

  const btnSize = useMemo(() => {
    if (!buttonSize) {
        return 'med'
    }
    return buttonSize
  }, [buttonSize])

  const classesBySelected = ((value: any, defaultValue: any, extra: string = '',
    trueClass: string, falseClass: string, compareNegative = false) => {
    if ((value === defaultValue) || (compareNegative && (value !== defaultValue))) {
      if (trueClass !== '') {
        if (extra === '') {
          return trueClass
        }
        return trueClass + ' ' + extra
      }
      return extra
    }
    if (falseClass !== '') {
      if (extra === '') {
        return falseClass
      }
      return falseClass + ' ' + extra
    }
    return extra
  })

  const linkClasses = ((value: any, defaultValue: any,
    extra: string = '', compareNegative = false) => {
    const base = `button-group-button button-basic button-${btnColor} button-${btnSize}`
    return classesBySelected(value, defaultValue, extra,
      `${base} button-group-button-selected`,
      `${base} button-group-button-not-selected button-outline`,
      compareNegative)
  })

  const selectedLabelClasses = () => {
    return `button-group-button button-basic button-${btnColor} button-${btnSize} button-group-button-selected`
  }

  const classes = useMemo(() => {
    const base = "button-group"
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const handleClickEvent = (value: string | boolean | number) => {
    setSelection(value)
    if (props.onClick) {
      props.onClick(value)
    }
  }

  const isSelected = (value: string | boolean | number | undefined): boolean => {
    if (!selection && !value) {
      return true
    }
    return selection === value
  }

  return (
    <div className={classes} role="group" aria-label={props.label}>
      {
        props.buttons.map((button) =>
          <div
            className="button-group-element"
            key={'div.' + props.groupName + button.value}
          >
            {isSelected(button.value) && button.href &&
              <a
                className={selectedLabelClasses()}
                href={button.href}
              >
                {button.caption}
              </a>
            }
            {!isSelected(button.value) && button.href &&
              <Link
                to={button.href}
                className={linkClasses(button.value, selection, props.buttonClass)}
              >
                {button.caption}
              </Link>
            }
            {isSelected(button.value) && !button.href && <>
              <input
                type="radio"
                className="button-check"
                name={props.groupName}
                id={props.groupName + button.value}
                autoComplete="off"
                onClick={() => {handleClickEvent(button.value)}}
              />
              <label
                className={linkClasses(button.value, selection, props.buttonClass)}
                htmlFor={props.groupName + button.value}>
                {button.caption}
              </label>
            </>}
            {!isSelected(button.value) && !button.href && <>
              <input
                type="radio"
                className="button-check"
                name={props.groupName}
                id={props.groupName + button.value}
                autoComplete="off"
                onClick={() => {handleClickEvent(button.value)}}
              />
              <label
                className={linkClasses(button.value, selection, props.buttonClass)}
                htmlFor={props.groupName + button.value}>
                {button.caption}
              </label>
            </>}
          </div>
        )}
    </div>
  )
}