import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import './Icon.css'

export const cssClassName = 'general-icon'

export interface IconProps {
  sz?: string
  className?: string
  color?: string
  FAIcon?: IconDefinition
}

export default function Icon(props: IconProps) {
  const { sz, className, color } = props
  const classes = useMemo(() => {
    let baseClass = cssClassName
    if (sz) {
      baseClass = `${baseClass} ${cssClassName}-${sz}`
    }
    if (color) {
      baseClass = `${baseClass} ${cssClassName}-${color}`
    }
    if (className) {
      baseClass = `${baseClass} ${className}`
    }
    return baseClass
  }, [sz, className, color])

  if (!props.FAIcon) {
    return <div></div>
  }

  return (
    <div className="generical-icon-container">
      <FontAwesomeIcon icon={props.FAIcon} className={classes} />
    </div>
  )
}
