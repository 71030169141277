import { LabelValuePairs } from "../../../types/types"

export interface FormDataLookup {
  [key: string]: string | number | boolean
}

export enum FormElementType {
  TextInput,
  TextArea,
  Radio,
  Checkbox,
  CheckBoxGroup,
  Select,
  ToggleSwitch,
  TimePicker,
  Label
}

export enum FormElementDataType {
  Text,
  Number,
  Boolean
}

export type FormElements = (FormElementTextInput |
  FormElementTextArea | FormElementSelect | FormElementToggleSwitch |
  FormElementTimePicker)[]

export const supportedElementTypes = ():FormElementType[] => {
  return [
    FormElementType.TextInput,
    FormElementType.TextArea,
    FormElementType.Radio,
    FormElementType.Checkbox,
    FormElementType.CheckBoxGroup,
    FormElementType.Select,
    FormElementType.ToggleSwitch,
    FormElementType.TimePicker,
    FormElementType.Label,
  ]
}

export interface FormElement {
  elementType: FormElementType
  dataType?: FormElementDataType
  fieldName: string
  label?: string
  id?: string
  labelClass?: string
  wrapperClass?: string
  helpText?: string
  disabled?: boolean
  inputRef?: any
  required?: boolean
  description?: string
  defaultValue?: string | number | boolean
}

export interface FormElementTextInput extends FormElement {
  autoComplete?: boolean
  autoCapitalize?: string
  autoCorrect?: boolean
  placeholder?: string
  inputClass?: string
}

export interface FormElementSelect extends FormElement {
  items: LabelValuePairs
  inputClass?: string
}

export interface FormElementCheckBox extends FormElement {
  value?: any
  defaultChecked?: boolean
  className?: string
}

export interface FormElementCheckBoxGroup extends FormElement {
  elements?: FormElementCheckBox[]
  className?: string
  horizontal?: boolean
}

export interface FormElementToggleSwitch extends FormElementCheckBox {
  sz?: string
}

export interface FormElementTextArea extends FormElementTextInput {
  footnote?: string
  isError?: boolean
}

export interface FormElementRadioGroup extends FormElement {
  items: LabelValuePairs
  radioLabelClass?: string
  radioWrapperClass?: string
}

export interface FormElementTimePicker extends FormElement {
  timeChangeFunc: any
  timeRange?: string
}

export const removeDeletes = (fdl: FormDataLookup): void => {
  const keys = Object.keys(fdl)
  for (const key of keys) {
    if (key.startsWith('DELETE.')) {
      delete fdl[key.replace('DELETE.', '')]
      delete fdl[key]
    }
  }
}