import { BasicLookup } from "../../types/types"
import { IChartJSDataset } from "./IChartJSData"

export interface LabelParts {
  node?: string
  position?: string
  metric?: string
}

export interface LabelOptions {
  label1: string // node
  label2: string // node - metric
  label3: string // node - position
  label4: string // node - metric - position
}

export function generateDatasetsLabels(dslist: IChartJSDataset[],
  tokens: BasicLookup[]) {
  if (!tokens || tokens.length === 0 || !dslist || dslist.length !== tokens.length) {
    return
  }

  const parts = [] as LabelParts[]
  parts.length = tokens.length

  for (const token of tokens) {
    const part = {
      node: token['node'] || undefined,
      position: token['position'] || undefined,
      metric: token['metric'] || undefined
    } as LabelParts
    if (part.position === "None") {
      part.position = undefined
    }
    parts.push(part)
  }
  const labels = generateUniqueLabels(parts)
  for (const i in dslist) {
    dslist[i].label = labels[i]
  }
}

export function generateUniqueLabels(inputs: LabelParts[]): string[] {
  if (!inputs || inputs.length === 0) {
    return []
  }

  const options = [] as LabelOptions[]

  for (const i in inputs) {
    const node = inputs[i].node || 'No Node'
    const opts = {} as LabelOptions
    opts.label1 = node
    opts.label2 = node
    opts.label3 = node
    opts.label4 = node
    opts.label2 = `${node} - ${inputs[i].metric}`
    if (inputs[i].position) {
      opts.label3 = `${node} - ${inputs[i].position}`
      opts.label4 = `${node} - ${inputs[i].metric} - ${inputs[i].position}`
    } else {
      opts.label4 = opts.label2
    }
    options.push(opts)
  }

  let canUseLabel1 = true
  let canUseLabel2 = true
  let canUseLabel3 = true
  let canUseLabel4 = true
  const labels1 = [] as string[]
  const labels2 = [] as string[]
  const labels3 = [] as string[]
  const labels4 = [] as string[]
  for (const o of options) {
    if (labels1.includes(o.label1)) {
      canUseLabel1 = false
    } else {
      labels1.push(o.label1)
    }
    if (labels2.includes(o.label2)) {
      canUseLabel2 = false
    } else {
      labels2.push(o.label2)
    }
    if (labels3.includes(o.label3)) {
      canUseLabel3 = false
    } else {
      labels3.push(o.label3)
    }
    if (labels4.includes(o.label4)) {
      canUseLabel4 = false
    } else {
      labels4.push(o.label4)
    }
  }
  if (!canUseLabel1 && !canUseLabel2 && !canUseLabel3 && !canUseLabel4) {
    canUseLabel4 = true
  }
  const out = [] as string[]
  for (const o of options) {
    if (canUseLabel1) {
      out.push(o.label1)
      continue
    }
    if (canUseLabel2) {
      out.push(o.label2)
      continue
    }
    if (canUseLabel3) {
      out.push(o.label3)
      continue
    }
    if (canUseLabel4) {
      out.push(o.label4)
      continue
    }
  }

  const out2 = [] as string[]
  const used = {} as any
  for (const s of out) {
    if (s in used) {
      out2.push(`${s} (${used[s]})`)
      used[s] = used[s] + 1
    } else {
      out2.push(s)
      used[s] = 2
    }
  }
  return out2
}