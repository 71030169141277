const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export const areEmailsValid = (input: string): boolean => {
  if (!input) {
    return true
  }
  const parts = input.replaceAll(' ', '').toLowerCase().split(',')
  for (const email of parts) {
    if (email === '') {
      continue
    }
    if (!(re.test(email))) {
      return false
    }
  }
  return true
}
