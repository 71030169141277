import { LabelValuePairs } from "../../types/types"
import { RadioGroup } from "../controls/Radios/RadioGroup"

import './YAxisSelector.css'

interface YAxisSelectorProps {
  labelClass?: string
  defaultValue?: string
  onChange?: any
  queryIndex?: number
}

export default function YAxisSelector(props: YAxisSelectorProps) {

  const vals =
  [
    {
      label: "Y1",
      value: "y"
    },
    {
      label: "Y2",
      value: "y2"
    }
  ] as LabelValuePairs

  return (
    <div className="y-axis-selector">
      <RadioGroup
        label="Axis"
        labelClass={props.labelClass}
        items={vals}
        defaultValue={props.defaultValue}
        groupName={"chartAxisLocationtStyle-" + props.queryIndex}
        onClick={props.onChange}
      />
    </div>
  )

}
