import { useContext, useEffect, useMemo } from "react"
import { FormDataLookup, FormElement, FormElementCheckBox, FormElementDataType, FormElementTextArea, FormElementType } from "../controls/forms/FormElement"
import { GeneralForm, SetCanSubmitFunc, SetFormDatafunc } from "../controls/forms/GeneralForm"
import { PrefsContext } from "../../prefs/PrefsContext"
import { GetLocalizedTextItem } from "../../localization/localization"
import { areEmailsValid } from "../../validation/email"

interface GenerateConfigureDeliveryProps {
  setFormDataFunc: SetFormDatafunc
  setCanContinueFunc: SetCanSubmitFunc
  setCanNavigateFunc: SetCanSubmitFunc
  formData: FormDataLookup
}


export function GenerateConfigureDelivery(props: GenerateConfigureDeliveryProps) {

  const { language } = useContext(PrefsContext)
  const { formData, setCanNavigateFunc } = props

  const isToError = useMemo((): boolean => {
    if (!formData.sendEmail) {
      return false
    }
    if (typeof formData.sendTo === 'string') {
      return !(areEmailsValid(formData.sendTo))
    }
    return false
  }, [formData])

  const isCCError = useMemo((): boolean => {
    if (!formData.sendEmail) {
      return false
    }
    if (typeof formData.sendCC === 'string') {
      return !(areEmailsValid(formData.sendCC))
    }
    return false
  }, [formData])

  const isBCCError = useMemo((): boolean => {
    if (!formData.sendEmail) {
      return false
    }
    if (typeof formData.sendBCC === 'string') {
      return !(areEmailsValid(formData.sendBCC))
    }
    return false
  }, [formData])

  const hasEmailErrors = useMemo((): boolean => {
    return isToError || isBCCError || isCCError
  }, [isToError, isBCCError, isCCError])

  useEffect(() => {
    setCanNavigateFunc(!hasEmailErrors)
  }, [hasEmailErrors, setCanNavigateFunc])

  const formElements = useMemo((): FormElement[] => {
    return [
    {
      label: GetLocalizedTextItem(language, 'sendReportViaEmail', 'upperAllFirst'),
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      value: true,
      fieldName: 'sendEmail',
      defaultChecked: 'sendEmail' in formData && formData.sendEmail === true,
      wrapperClass: 'mt-2'
    } as FormElementCheckBox,
    {
      label: GetLocalizedTextItem(language, 'to', 'upperAllFirst'),
      elementType: FormElementType.TextArea,
      dataType: FormElementDataType.Text,
      labelClass: 'width-35',
      fieldName: 'sendTo',
      wrapperClass: 'mt-2',
      inputClass: 'wide',
      disabled: !('sendEmail' in formData) || formData.sendEmail !== true,
      defaultValue: formData.sendTo,
      isError: isToError
    } as FormElementTextArea,
    {
      label: GetLocalizedTextItem(language, 'cc', 'upper'),
      elementType: FormElementType.TextArea,
      dataType: FormElementDataType.Text,
      labelClass: 'width-35',
      fieldName: 'sendCC',
      wrapperClass: 'mt-2',
      inputClass: 'wide',
      disabled: !('sendEmail' in formData) || formData.sendEmail !== true,
      defaultValue: formData.sendCC,
      isError: isCCError
    } as FormElementTextArea,
    {
      label: GetLocalizedTextItem(language, 'bcc', 'upper'),
      elementType: FormElementType.TextArea,
      dataType: FormElementDataType.Text,
      labelClass: 'width-35',
      fieldName: 'sendBCC',
      wrapperClass: 'mt-2',
      inputClass: 'wide',
      disabled: !('sendEmail' in formData) || formData.sendEmail !== true,
      defaultValue: formData.sendBCC,
      isError: isBCCError
    } as FormElementTextArea
  ] as FormElement[]
  }, [formData, language, isToError, isBCCError, isCCError])

  return <div className="generate-configure-growers-report-form">
    <div className="generate-report-section-header short">
      Delivery Settings
    </div>
    <div className="generate-report-type-description-label">
      Reports can be downloaded in the Grower Portal.<br />You can also have reports e-mailed to your team.
    </div>
    <GeneralForm
      elements={formElements}
      setFormDatafunc={props.setFormDataFunc}
      setCanSubmitFunc={props.setCanContinueFunc}
    />
    {!hasEmailErrors && <div className="generate-report-type-description-label-indented">
      {GetLocalizedTextItem(language, 'separateAddressesWithComma', 'upperFirst')}
    </div>}
    {hasEmailErrors && <div className="generate-report-type-description-label-indented alert-color">
      Invalid Email Addresses provided.
    </div>}
  </div>
}