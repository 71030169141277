import Routes from "./routing/Router"

import AuthProvider from './auth/provider'
import { PrefsProvider } from "./prefs/PrefsContext"

import FlashMessage from "./components/notifications/FlashMessage/FlashMessage"
import { FlashMessageProvider } from "./components/notifications/FlashMessage/FlashMessageContext"

function App() {
  return (
    <AuthProvider>
      <PrefsProvider>
        <FlashMessageProvider>
          <FlashMessage />
          <Routes />
        </FlashMessageProvider>
      </PrefsProvider>
    </AuthProvider>
  )
}
export default App
