import { useMemo } from 'react'
import './RadioGroup.css'
import Radio from './Radio'
import { LabelValuePairs } from '../../../types/types'

interface RadioGroupProps {
  label?: string
  groupName?: string
  defaultValue?: string
  onClick?: any
  className?: string
  labelClass?: string
  radioLabelClass?: string
  radioWrapperClass?: string
  wrapperClass?: string
  items?: LabelValuePairs
  disabled?: boolean
  id?: string
  required?: boolean
}

export function RadioGroup(props: RadioGroupProps) {

  const { className, labelClass, wrapperClass, disabled, required } = props

  const wrapperClasses = useMemo(() => {
    const base = 'radio-group'
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  const classes = useMemo(() => {
    const base = 'input-control'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const labelClasses = useMemo(() => {
    let base = 'radio-group-label'
    if (disabled) {
      base = `${base} disabled`
    }
    if (required) {
      base = `${base} required`
    }
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [labelClass, disabled, required])

  return (
    <>
      <div className={wrapperClasses}>
        {props.label && <div className={labelClasses}>
          {props.label}:
        </div>}
          {props.items && props.items.length > 0 &&
            props.items.map((val) => {
              return (
                <Radio
                  onClick={props.onClick}
                  value={val.value}
                  label={val.label}
                  labelClass={props.radioLabelClass}
                  wrapperClass={props.radioWrapperClass}
                  key={ val.value}
                  name={props.groupName}
                  id={props.groupName + '.' + val.value}
                  className={classes}
                  defaultChecked={val.value === props.defaultValue}
                  disabled={disabled}
                />
              )
            })
          }
      </div>
    </>
  )
}
