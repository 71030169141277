import { useEffect, useState } from 'react'

import Builder from '../chart/Builder'
import Library from '../chart/Library'
import { ButtonGroup, ButtonConfig } from '../controls/ButtonGroup/ButtonGroup'
import { useParams } from 'react-router-dom'

const buttons = [
  {
    caption: 'Chart Library',
    value: 'library',
    href: '/charts'
  },
  {
    caption: 'Chart Builder',
    value: 'builder',
    href: '/charts/builder'
  }
] as ButtonConfig[]

export function Charts() {
  const { section } = useParams()
  const [activePill, setActivePill] = useState(section || 'library')
  const [refreshCount, setRefreshCount] = useState(0)

  useEffect(() => {
    setActivePill(section || 'library')
    setRefreshCount((x: number) => {
      return x + 1
    })
  }, [section, setRefreshCount, setActivePill])

  return (
    <div className="content-pane" key={refreshCount}>
      <div>
        <ButtonGroup
          className="pillbox centered wide mb-2"
          groupName="chart-tab-select"
          buttons={buttons}
          defaultValue={activePill}
        />
      </div>
      <div>
        {activePill === 'library' &&
          <Library className="fade-in" />}
        {activePill === 'builder' &&
          <Builder className="fade-in" />}
      </div>
    </div>
  )

}
