import  NavLink , { MainNavLinkProps } from '../navigation/NavLink'

import './NavMenu.css'

interface NavMenuProps {
  items: MainNavLinkProps[]
}

export default function NavMenu(props: NavMenuProps) {
  return <ul className="nav-menu">
    {
      props.items.map((item) => {
        return (
          <NavLink
            id={item.id}
            key={item.id}
            label={item.label}
            to={item.to}
            cellClassName={item.cellClassName}
            iconClassName={item.iconClassName}
            className={item.className}
            icon={item.icon}
            isDivider={item.isDivider}
            onClick={item.onClick}
            hideInProd={item.hideInProd}
            showInLocalDevOnly={item.showInLocalDevOnly}
          />
        )
      })
    }
  </ul>
}
